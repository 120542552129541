export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_CHECK = 'AUTH_CHECK';
export const RESET_PASSWORD_START = 'RESET_PASSWORD_START';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';
export const CHANGE_PASSWORD_START = 'CHANGE_PASSWORD_START';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAIL = 'CHANGE_PASSWORD_FAIL';
export const CLEAR_ERROR_AUTH = 'CLEAR_ERROR_AUTH';

export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

export const SET_DIALOG_BOX = 'SET_DIALOG_BOX';
export const REMOVE_DIALOG_BOX = 'REMOVE_DIALOG_BOX';

//USER
export const FETCH_USERS_START = 'FETCH_USERS_START';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAIL = 'FETCH_USERS_FAIL';
export const GET_USER_START = 'GET_USER_START';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAIL = 'GET_USER_FAIL';
export const STORE_USER_START = 'STORE_USER_START';
export const STORE_USER_SUCCESS = 'STORE_USER_SUCCESS';
export const STORE_USER_FAIL = 'STORE_USER_FAIL';
export const UPDATE_USER_START = 'UPDATE_USER_START';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';
export const DELETE_USER_START = 'DELETE_USER_START';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAIL = 'DELETE_USER_FAIL';

//PROFILE
export const GET_PROFILE_START = 'GET_PROFILE_START';
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS';
export const GET_PROFILE_FAIL = 'GET_PROFILE_FAIL';
export const UPDATE_PROFILE_NAME_START = 'UPDATE_PROFILE_NAME_START';
export const UPDATE_PROFILE_NAME_SUCCESS = 'UPDATE_PROFILE_NAME_SUCCESS';
export const UPDATE_PROFILE_NAME_FAIL = 'UPDATE_PROFILE_NAME_FAIL';
export const UPDATE_PROFILE_PASSWORD_START = 'UPDATE_PROFILE_PASSWORD_START';
export const UPDATE_PROFILE_PASSWORD_SUCCESS = 'UPDATE_PROFILE_PASSWORD_SUCCESS';
export const UPDATE_PROFILE_PASSWORD_FAIL = 'UPDATE_PROFILE_PASSWORD_FAIL';

//Scan
export const FETCH_SCANS_START = 'FETCH_SCANS_START';
export const FETCH_SCANS_SUCCESS = 'FETCH_SCANS_SUCCESS';
export const FETCH_SCANS_FAIL = 'FETCH_SCANS_FAIL';
export const STORE_SCAN_START = 'STORE_SCAN_START';
export const STORE_SCAN_SUCCESS = 'STORE_SCAN_SUCCESS';
export const STORE_SCAN_FAIL = 'STORE_SCAN_FAIL';
export const UPDATE_SCAN_START = 'UPDATE_SCAN_START';
export const UPDATE_SCAN_SUCCESS = 'UPDATE_SCAN_SUCCESS';
export const UPDATE_SCAN_FAIL = 'UPDATE_SCAN_FAIL';
export const CLOSED_DIALOG = 'CLOSED_DIALOG';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const PRODUCT_CHECK_START = 'PRODUCT_CHECK_START';
export const PRODUCT_CHECK_SUCCESS = 'PRODUCT_CHECK_SUCCESS';
export const PRODUCT_CHECK_FAIL = 'PRODUCT_CHECK_FAIL';
export const GET_PRODUCT_DETAIL_START = 'GET_PRODUCT_DETAIL_START';
export const GET_PRODUCT_DETAIL_SUCCESS = 'GET_PRODUCT_DETAIL_SUCCESS';
export const GET_PRODUCT_DETAIL_FAIL = 'GET_PRODUCT_DETAIL_FAIL';

//vendor
export const FETCH_VENDORS_START = 'FETCH_VENDORS_START';
export const FETCH_VENDORS_SUCCESS = 'FETCH_VENDORS_SUCCESS';
export const FETCH_VENDORS_FAIL = 'FETCH_VENDORS_FAIL';
export const STORE_VENDOR_START = 'STORE_VENDOR_START';
export const STORE_VENDOR_SUCCESS = 'STORE_VENDOR_SUCCESS';
export const STORE_VENDOR_FAIL = 'STORE_VENDOR_FAIL';
export const UPDATE_VENDOR_START = 'UPDATE_VENDOR_START';
export const UPDATE_VENDOR_SUCCESS = 'UPDATE_VENDOR_SUCCESS';
export const UPDATE_VENDOR_FAIL = 'UPDATE_VENDOR_FAIL';
export const DELETE_VENDOR_START = 'DELETE_VENDOR_START';
export const DELETE_VENDOR_SUCCESS = 'DELETE_VENDOR_SUCCESS';
export const DELETE_VENDOR_FAIL = 'DELETE_VENDOR_FAIL';
export const CLEAR_ERROR_VENDOR = 'CLEAR_ERROR_VENDOR';

//product
export const FETCH_PRODUCTS_START = 'FETCH_PRODUCTS_START';
export const FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS';
export const FETCH_PRODUCTS_FAIL = 'FETCH_PRODUCTS_FAIL';
export const GET_PRODUCT_START = 'GET_PRODUCT_START';
export const GET_PRODUCT_SUCCESS = 'GET_PRODUCT_SUCCESS';
export const GET_PRODUCT_FAIL = 'GET_PRODUCT_FAIL';
export const STORE_PRODUCT_START = 'STORE_PRODUCT_START';
export const STORE_PRODUCT_SUCCESS = 'STORE_PRODUCT_SUCCESS';
export const STORE_PRODUCT_FAIL = 'STORE_PRODUCT_FAIL';
export const UPDATE_PRODUCT_START = 'UPDATE_PRODUCT_START';
export const UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS';
export const UPDATE_PRODUCT_FAIL = 'UPDATE_PRODUCT_FAIL';
export const DELETE_PRODUCT_START = 'DELETE_PRODUCT_START';
export const DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS';
export const DELETE_PRODUCT_FAIL = 'DELETE_PRODUCT_FAIL';
export const GET_ALL_PRODUCT_START = 'GET_ALL_PRODUCT_START';
export const GET_ALL_PRODUCT_SUCCESS = 'GET_ALL_PRODUCT_SUCCESS';
export const GET_ALL_PRODUCT_FAIL = 'GET_ALL_PRODUCT_FAIL';
export const CLEAR_ERROR_PRODUCT = 'CLEAR_ERROR_PRODUCT';