import React, {Fragment, useEffect,useState} from "react";
import {
  Main
} from './style';
import {Button, Grid,Link,Breadcrumbs, Typography} from "@material-ui/core";
import {PermIdentity} from "@material-ui/icons";
import {List,Delete} from "./components";
import * as actions from '../../store/actions';
import {connect} from "react-redux";
import {Loading} from "../../components/UI";
import {CustomRouterLink} from "../../shared/utility";
import {Modal} from '../../components/UI'
import {useHistory} from 'react-router-dom'

const ManagementUser = props => {
  const {
    onFetchUsers,
    loading,
    onDeleteUser,
    changing
  } = props;
  const classes = Main();
  const history = useHistory();

  useEffect(() => {
    onFetchUsers(1)
    closedModalDialog()
  },[onFetchUsers,changing]);

  const handleChangePage = (page) => {
    onFetchUsers(page)
  }

  const [Form, setForm] = useState('');
  const [modalState, setModalState] = useState({
    open: false,
    title: '',
    maxWidth: 'sm',
  });
  const closedModalDialog = () => {
    setModalState({
      maxWidth: 'sm',
      title: '',
      open: false,
    });
    setForm('');
  };

  const dialogDelete = data => {
    setModalState({
      open: true,
      title: 'Hapus User',
      maxWidth: 'sm'
    });
    setForm(<Delete classes={classes} data={data} closedModalDialog={closedModalDialog} deleted={(id) => deleted(id)}/>)
  };

  const deleted = id => {
    onDeleteUser(id, history);
  }

  return (loading ? <Loading/> :
    <Fragment>
      <Modal
        maxWidth={modalState.maxWidth}
        open={modalState.open}
        title={modalState.title}
        onCloseModal={closedModalDialog}
        contentModal={Form}
      />
      <div className={classes.root}>
        <div className={classes.row}>
          <Grid container justify={"space-between"}>
            <Grid item>
              <div>
                <div className={classes.tagMenu}>
                  Management User
                </div>
                <div>
                  <Breadcrumbs aria-label="breadcrumb">
                    <Link color="inherit" component={CustomRouterLink} to="/dashboard">
                      Dashboard
                    </Link>
                    <Typography color="textPrimary">Management User</Typography>
                  </Breadcrumbs>
                </div>
              </div>

            </Grid>
            <Grid item>
              <Button
                variant={"contained"}
                size={"medium"}
                startIcon={React.cloneElement(<PermIdentity/>)}
                className={classes.btnWarning}
                component={CustomRouterLink}
                to={'/management-user-create'}
              >
                Tambah User
              </Button>
            </Grid>
          </Grid>
        </div>

        <div className={classes.row}>
          <List classes={classes} dialogDelete={(data) => dialogDelete(data)} propsHandleChangePage={(page) => handleChangePage(page)}/>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    users: state.user.users,
    loading: state.user.loading,
    changing: state.user.changing
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchUsers: (page) => dispatch(actions.fetchUsers(page)),
    onDeleteUser: (id,history) => dispatch(actions.deleteUser(id,history))
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(ManagementUser);