import React, {Fragment} from "react";
import {connect} from "react-redux";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Paper from "@material-ui/core/Paper";
import {isEmpty} from "../../../../shared/utility";
import TableData from './TableData/TableData';
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableBody from "@material-ui/core/TableBody";
// import TablePaginationsArrayActions from '../../../../components/UI/Table/TablePaginationArrayActions/TablePaginationsArrayActions'
import {TablePaginationActions} from "../../../../components/UI";
import {useHistory} from "react-router-dom";

const columns = [
  { id: 'no', label: 'No', minWidth: 20 },
  { id: 'name', label: 'Name', minWidth: 100 },
  { id: 'weight', label: 'Weight', minWidth: 100 },
  { id: 'unit', label: 'Unit', minWidth: 100 },
  { id: 'action', label: 'Action', minWidth: 100,align:'left' },
];

const List = props => {
  const {
    classes,
    products,
    deleteProps,
    propsHandleChangePage
  } = props;

  const history = useHistory();

  const handleChangePage = (event, newPage) => {
    propsHandleChangePage(newPage);
  };

  const deleteData = data => {
    deleteProps(data)
  }

  let tableBody = '';
  if(!isEmpty(products)){
    const countRows = products.data.length
    const page = products.current_page - 1;
    const rowsPerPage = 10;

    tableBody = (
      <Fragment>
        <TableBody>
          {products.data.map((product, index) => (
            <TableData
              product={product}
              key={product.id}
              index={index}
              classes={classes}
              deleted={(data) => deleteData(data)}
              edited={(data) => history.push(`product/${data.id}`)}
            />
          ))}
        </TableBody>

        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[rowsPerPage]}
              colSpan={5}
              count={countRows}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Fragment>
    )
  }

  return (
    <Fragment>
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table" style={{ minWidth: "340px" }}>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {tableBody}
          </Table>
        </TableContainer>
      </Paper>
    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    products: state.product.products
  };
};

export default connect(mapStateToProps)(List);