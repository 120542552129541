import React, {forwardRef} from "react";
import {Link as RouterLink} from "react-router-dom";

export const updateObject = (oldObject, updateProperties) => {
  return {
    ...oldObject,
    ...updateProperties
  }
}

export const isEmpty = (obj) => {
  for(var key in obj) {
    if(obj.hasOwnProperty(key))
      return false;
  }
  return true;
}

export const CustomRouterLink = forwardRef((props, ref) => (
  <div
    ref={ref}
    // style={{ flexGrow: 1 }}
  >
    <RouterLink {...props} />
  </div>
));
