import React, {Fragment, useEffect,useState} from "react";
import {
  Main
} from './style';
import {Button, Grid,Link,Breadcrumbs, Typography} from "@material-ui/core";
import {StorefrontOutlined} from "@material-ui/icons";
import {Delete, List} from "./compenents";
import * as actions from '../../store/actions';
import {connect} from "react-redux";
import {Loading} from "../../components/UI";
import {CustomRouterLink} from "../../shared/utility";
import {Modal} from '../../components/UI'
// import {useHistory} from 'react-router-dom'

const Product = props => {
  const {
    onFetchProducts,
    loading,
    changing,
    onDeleteProduct
  } = props;
  const classes = Main();

  useEffect(() => {
    onFetchProducts(1)
    closedModalDialog()
  },[onFetchProducts,changing]);

  const handleChangePage = page => {
    onFetchProducts(page)
  }

  const [Form, setForm] = useState('');
  const [modalState, setModalState] = useState({
    open: false,
    title: '',
    maxWidth: 'sm',
  });
  const closedModalDialog = () => {
    setModalState({
      maxWidth: 'sm',
      title: '',
      open: false,
    });
    setForm('');
  };

  const deleteData = data => {
    console.log(data);
    setModalState({
      maxWidth: 'sm',
      title: 'Delete Product',
      open: true,
    });
    setForm(<Delete data={data} classes={classes} closedModalDialog={() => closedModalDialog()} deleted={(id) => deleted(id)}/>);
  }

  const deleted = id => {
    onDeleteProduct(id)
  }

  return (loading ? <Loading/> :
      <Fragment>
        <Modal
          maxWidth={modalState.maxWidth}
          open={modalState.open}
          title={modalState.title}
          onCloseModal={closedModalDialog}
          contentModal={Form}
        />
        <div className={classes.root}>
          <div className={classes.row}>
            <Grid container justify={"space-between"}>
              <Grid item>
                <div>
                  <div className={classes.tagMenu}>
                    Management Product
                  </div>
                  <div>
                    <Breadcrumbs aria-label="breadcrumb">
                      <Link color="inherit" component={CustomRouterLink} to="/dashboard">
                        Dashboard
                      </Link>
                      <Typography color="textPrimary">Management Product</Typography>
                    </Breadcrumbs>
                  </div>
                </div>

              </Grid>
              <Grid item>
                <Button
                  variant={"contained"}
                  size={"medium"}
                  startIcon={React.cloneElement(<StorefrontOutlined/>)}
                  className={classes.btnWarning}
                  component={CustomRouterLink}
                  to={'/product-create'}
                >
                  Tambah Product
                </Button>
              </Grid>
            </Grid>
          </div>

          <div className={classes.row}>
            <List classes={classes} deleteProps={(data) => deleteData(data)} propsHandleChangePage={(page) => handleChangePage(page)} />
          </div>
        </div>
      </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    products: state.product.products,
    loading: state.product.loading,
    changing: state.product.changing
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchProducts: (page) => dispatch(actions.fetchProducts(page)),
    onDeleteProduct: (id) => dispatch(actions.deleteProduct(id))
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(Product);