import palette from '../palette';
import typography from '../typography';
console.log(typography.body1)
const MuiTableCell = {
  root: {
    ...typography.body1,
    borderBottom: `1px solid ${palette.divider}`,
    // fontSize: '15px'
  }
}

export default MuiTableCell;
