import React, {Fragment, useEffect} from "react";
import {useParams,useHistory} from 'react-router-dom';
import {Loading} from "../../../../components/UI";
import * as actions from "../../../../store/actions";
import {connect} from "react-redux";

const CheckProductGold = props => {
  const history = useHistory();
  const {c} = useParams();
  const {
    onProductCheck,
    loading,
  } = props;

  useEffect(() => {
    onProductCheck(c, history)
  },[onProductCheck, c, history])

  return (loading ? <Loading/> :
      <Fragment>
        Loading...
      </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    loading: state.scan.loading
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onProductCheck: (code, history) => dispatch(actions.productCheck(code, history))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckProductGold);