import React, {Fragment, useState} from "react";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FormHelperText from "@material-ui/core/FormHelperText";
import {Button, Grid} from "@material-ui/core";
import * as actions from "../../../../store/actions";
import {connect} from "react-redux";
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers';

const schema = yup.object().shape({
});

const Update = props => {
  const {
    classes,
    scan,
    errors,
    onUpdateScan,
    closedModalDialog
  } = props;

  const [formState, setFormState] = useState({
    name: scan.name,
    code: scan.code,
    vendor_name: scan.vendor_name,
    user: scan.user? scan.user.name : '',
    status: ''
  });

  const handleChange = (event) => {
    const target = event.target.name
    event.persist()

    if(event.target.type === 'checkbox'){
      setFormState((formState) => ({
        ...formState,
        [target]: event.target.checked
      }))
    }else{
      setFormState((formState) => ({
        ...formState,
        [target]: event.target.value
      }))
    }
  }

  const { register, handleSubmit } = useForm({
    resolver: yupResolver(schema)
  })

  const onSubmit = data => {
    // console.log(scan.id, data)
    onUpdateScan(scan.id,data);
  }

  return (
   <Fragment>
     <form onSubmit={handleSubmit(onSubmit)}>
       <Paper className={classes.root}>
         <TextField
           className={classes.marginForm}
           label="Gold Name"
           variant={"outlined"}
           defaultValue={formState.name}
           onChange={handleChange}
           size={"small"}
           fullWidth
           disabled
         />

         <TextField
           className={classes.marginForm}
           label="Gold Code"
           variant={"outlined"}
           defaultValue={formState.code}
           onChange={handleChange}
           size={"small"}
           fullWidth
           disabled
         />

         <TextField
           className={classes.marginForm}
           label="Vendor Name"
           variant={"outlined"}
           defaultValue={formState.vendor_name}
           onChange={handleChange}
           size={"small"}
           fullWidth
           disabled
         />

         <TextField
           className={classes.marginForm}
           label="Last Scan ID"
           variant={"outlined"}
           defaultValue={formState.user}
           onChange={handleChange}
           size={"small"}
           fullWidth
           disabled
         />

         <div>
           <FormControl
             error={errors.status ? true : false}
             variant="outlined" fullWidth size={"small"} className={classes.marginForm}>
             <InputLabel htmlFor="outlined-age-native-simple">Status</InputLabel>
             <Select
               fullWidth
               native
               defaultValue={formState.status}
               onChange={handleChange}
               label="status"
               inputProps={{
                 name: 'status',
                 id: 'outlined-age-native-simple'
               }}
               name="platform"
               inputRef={register}
               // onChange={handleChange}
             >
               <option aria-label="None" value="" />
               <option value={'invalid'}>Invalid</option>
               {/*<option value={'not_scanned'}>Not Scanned</option>*/}
             </Select>
             <FormHelperText>{errors.status && errors.status[0]}</FormHelperText>
           </FormControl>
         </div>

         <Grid container justify={"flex-end"} spacing={2}>
           <Grid item>
             <Button variant={"contained"} size={"medium"} className={classes.btnWarning} onClick={() => closedModalDialog()}>
               Cancel
             </Button>
           </Grid>
           <Grid item>
             <Button variant={"contained"} size={"medium"} className={classes.btnSuccess} type={"submit"}>
               Update
             </Button>
           </Grid>
         </Grid>
       </Paper>
     </form>
   </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    loading: state.scan.loading,
    errors: state.scan.errors
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUpdateScan: (id, storeData) => dispatch(actions.updateScan(id, storeData))
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(Update);