import React,{Fragment} from "react";
import {IconButton, TableCell, TableRow, Tooltip} from "@material-ui/core";
import {Delete, Edit} from "@material-ui/icons";

const PurchaseOrderData = props => {
  const {vendor, index,classes,deleted, edited} = props;

  return (
    <Fragment>
      <TableRow key={vendor.id}>
        <TableCell>
          {index+1}
        </TableCell>
        <TableCell className={classes.textCapitalize}>
          {vendor.name}
        </TableCell>
        <TableCell>
          {vendor.address}
        </TableCell>
        <TableCell className={classes.textCapitalize}>
          {vendor.phone}
        </TableCell>
        <TableCell className={classes.textCapitalize}>
          {vendor.contact}
        </TableCell>
        <TableCell>
          <Tooltip title="Edit Vendor">
            <IconButton aria-label="edit" className={classes.textPrimary} onClick={() => edited(vendor)}>
              <Edit />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete Vendor">
            <IconButton aria-label="delete" className={classes.textError} onClick={() => deleted(vendor)}>
              <Delete />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
    </Fragment>
  );
};

export default PurchaseOrderData;