import * as actions from './actionTypes'
import axios from '../../axios-orders'
import { setAlert } from './alert'

export const fetchProductsStart = () => {
  return {
    type: actions.FETCH_PRODUCTS_START
  }
}

export const fetchProductsSuccess = (products) => {
  return {
    type: actions.FETCH_PRODUCTS_SUCCESS,
    products: products
  }
}

export const fetchProductsFail = (errors) => {
  return {
    type: actions.FETCH_PRODUCTS_FAIL,
    errors: errors
  }
}

export const fetchProducts = (page) => {
  return dispatch => {
    dispatch(fetchProductsStart())

    axios.get(`/api/admin/product?page=${page}`, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`
      }
    })
      .then(response => {
        dispatch(fetchProductsSuccess(response.data))
      })
      .catch(err => {
        dispatch(fetchProductsFail(err.response.data.message))
        dispatch(setAlert(err.response.data.message, "error"))
      })
  }
}

export const getProductStart = () => {
  return {
    type: actions.GET_PRODUCT_START
  }
}

export const getProductSuccess = (product) => {
  return {
    type: actions.GET_PRODUCT_SUCCESS,
    product: product
  }
}

export const getProductFail = (errors) => {
  return {
    type: actions.GET_PRODUCT_FAIL,
    errors: errors
  }
}

export const getProduct = (id) => {
  return dispatch => {
    dispatch(getProductStart())

    axios.get(`/api/admin/product/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`
      }
    })
      .then(response => {
        dispatch(getProductSuccess(response.data))
      })
      .catch(err => {
        dispatch(getProductFail(err.response.data.message))
        dispatch(setAlert(err.response.data.message, "error"))
      })
  }
}

export const getAllProductStart = () => {
  return {
    type: actions.GET_ALL_PRODUCT_START
  }
}

export const getAllProductSuccess = (allProduct) => {
  return {
    type: actions.GET_ALL_PRODUCT_SUCCESS,
    allProduct: allProduct
  }
}

export const getAllProductFail = (errors) => {
  return {
    type: actions.GET_ALL_PRODUCT_FAIL,
    errors: errors
  }
}

export const getAllProduct = () => {
  return dispatch => {
    dispatch(getAllProductStart())

    axios.get(`/api/admin/product/all`, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`
      }
    })
      .then(response => {
        dispatch(getAllProductSuccess(response.data))
      })
      .catch(err => {
        dispatch(getAllProductFail(err.response.data.message))
        dispatch(setAlert(err.response.data.message, "error"))
      })
  }
}

export const storeProductStart = () => {
  return {
    type: actions.STORE_PRODUCT_START
  }
}

export const storeProductSuccess = () => {
  return {
    type: actions.STORE_PRODUCT_SUCCESS,
  }
}

export const storeProductFail = (errors) => {
  return {
    type: actions.STORE_PRODUCT_FAIL,
    errors: errors
  }
}

export const storeProduct = (storeData,history) => {
  return dispatch => {
    dispatch(storeProductStart())
    axios.post(`api/admin/product`,storeData, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`
      }
    })
      .then(response => {
        dispatch(setAlert(response.data.message, 'success'))
        dispatch(storeProductSuccess())
        history.push(`/product`);
      })
      .catch(error => {
        dispatch(storeProductFail(error.response.data.errors ? error.response.data.errors : {}))
        dispatch(setAlert(error.response.data.message, 'error'))
      })
  }
}

export const updateProductStart = () => {
  return {
    type: actions.UPDATE_PRODUCT_START
  }
}

export const updateProductSuccess = () => {
  return {
    type: actions.UPDATE_PRODUCT_SUCCESS
  }
}

export const updateProductFail = (errors) => {
  return {
    type: actions.UPDATE_PRODUCT_FAIL,
    errors: errors
  }
}

export const updateProduct = (id,storeData, history) => {
  return dispatch => {
    dispatch(updateProductStart())
    axios.put(`api/admin/product/${id}`,storeData, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`
      }
    })
      .then(response => {
        dispatch(setAlert(response.data.message, 'success'))
        dispatch(updateProductSuccess(response.data))
        history.push(`/product`);
      })
      .catch(error => {
        dispatch(updateProductFail(error.response.data.errors ? error.response.data.errors : {}))
        dispatch(setAlert(error.response.data.message, 'error'))
      })
  }
}

export const deleteProductStart = () => {
  return {
    type: actions.DELETE_PRODUCT_START
  }
}

export const deleteProductSuccess = () => {
  return {
    type: actions.DELETE_PRODUCT_SUCCESS
  }
}

export const deleteProductFail = (errors) => {
  return {
    type: actions.DELETE_PRODUCT_FAIL,
    errors: errors
  }
}

export const deleteProduct = (id) => {
  return dispatch => {
    dispatch(deleteProductStart())
    axios.delete(`api/admin/product/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`
      }
    })
      .then(response => {
        dispatch(setAlert(response.data.message, 'success'))
        dispatch(deleteProductSuccess(response.data))
        // history.push(`/product`);
      })
      .catch(error => {
        dispatch(deleteProductFail(error.response.data.message ? error.response.data.message : {}))
        dispatch(setAlert(error.response.data.message, 'error'))
      })
  }
}

export const clearErrorProduct = () => {
  return {
    type: actions.CLEAR_ERROR_PRODUCT
  }
}