import React, {Fragment, useState} from "react";
import {connect} from "react-redux";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Paper from "@material-ui/core/Paper";
import {isEmpty} from "../../../../shared/utility";
import TableData from './TableData/TableData';
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableBody from "@material-ui/core/TableBody";
import TablePaginationsArrayActions from '../../../../components/UI/Table/TablePaginationArrayActions/TablePaginationsArrayActions'

const columns = [
  { id: 'no', label: 'No', minWidth: 20 },
  { id: 'name', label: 'Name', minWidth: 100 },
  { id: 'address', label: 'Address', minWidth: 100 },
  { id: 'phone', label: 'Phone', minWidth: 100 },
  { id: 'contact', label: 'PIC Vendor', minWidth: 100 },
  { id: 'action', label: 'Action', minWidth: 100 },
];

const List = props => {
  const {
    classes,
    vendors,
    deleteProps,
    updateProps
  } = props;

  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(5)

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const updateData = data => {
   updateProps(data)
  }

  const deleteData = data => {
    deleteProps(data)
  }

  let tableBody = '';
  if(!isEmpty(vendors)){
    const countRows = vendors.data.length

    tableBody = (
      <Fragment>
        <TableBody>
          {vendors.data.map((vendor, index) => (
            <TableData
              vendor={vendor}
              key={vendor.id}
              index={index}
              classes={classes}
              deleted={(data) => deleteData(data)}
              edited={(data) => updateData(data)}
            />
          )).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
        </TableBody>

        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[rowsPerPage]}
              colSpan={6}
              count={countRows}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              ActionsComponent={TablePaginationsArrayActions}
            />
          </TableRow>
        </TableFooter>
      </Fragment>
    )
  }

  return (
    <Fragment>
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table" style={{ minWidth: "340px" }}>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {tableBody}
          </Table>
        </TableContainer>
      </Paper>
    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    vendors: state.vendor.vendors
  };
};

export default connect(mapStateToProps)(List);