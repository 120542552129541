import React, {Fragment, useEffect, useState} from "react";
import {Main} from './style';
import {Breadcrumbs, Grid, Typography} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import {CalendarToday, CropFree, Search} from "@material-ui/icons";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
// import MuiPickersUtilsProvider from "@material-ui/pickers/MuiPickersUtilsProvider";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import {DatePicker,MuiPickersUtilsProvider} from "@material-ui/pickers";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import {List, Update} from "./components";
import * as actions from '../../store/actions';
import {connect} from "react-redux";
import {Loading, Modal} from "../../components/UI";
import {isEmpty} from "../../shared/utility";

const Dashboard = props => {
  const classes = Main();
  const {
    onFetchScans,
    loading,
    scans,
    changing,
    onFetchVendors,
    onGetAllProduct,
    allProduct,
    vendors
  } = props;

  const [formSearch, setFormSearch] = useState({
    status: '',
    search: '',
    start_date: moment(new Date()).format('YYYY-MM-DD'),
    end_date: moment(new Date()).format('YYYY-MM-DD'),
    product_id: '',
    vendor_id: '',
    // round_id: ''
  });

  const [ startDate, setStartDate ] = useState({
    startDate: new Date(),
  });

  const handleStartDate = (input) => {
    setStartDate(startDate => ({
      ...startDate,
      startDate: input
    }));
    setFormSearch(() => ({
      ...formSearch,
      start_date: moment(input).format('YYYY-MM-DD')
    }));
  };

  const [ endDate, setEndDate ] = useState({
    endDate: new Date(),
  });

  const handleEndDate = (input) => {
    setEndDate(endDate => ({
      ...endDate,
      endDate: input
    }));
    setFormSearch(() => ({
      ...formSearch,
      end_date: moment(input).format('YYYY-MM-DD')
    }));
  };

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenEnd, setIsOpenEnd] = useState(false);

  const handleSearch = event => {
    const target = event.target.name;
    event.persist();
    setFormSearch((formSearch) => ({
      ...formSearch,
      [target]: event.target.value
    }));
  };

  // useEffect(())

  useEffect(() => {
    closedModalDialog()
    onFetchVendors()
    onGetAllProduct()
    const timer = setTimeout(() => {
      onFetchScans(1, formSearch)
    }, 1000)

    return () => clearTimeout(timer)
    // onFetchPurchaseOrders(1, formSearch)
  },[onFetchScans,formSearch,changing,onGetAllProduct,onFetchVendors]);

  const handleChangePage = (page) => {
    onFetchScans(page, formSearch)
  }

  const [Form, setForm] = useState('');
  const [modalState, setModalState] = useState({
    open: false,
    title: '',
    maxWidth: 'sm',
  });
  const closedModalDialog = () => {
    setModalState({
      maxWidth: 'sm',
      title: '',
      open: false,
    });
    setForm('');
  };

  const update = data => {
    console.log(data);
    setModalState({
      open: true,
      title: 'Update Status Scan',
      maxWidth: 'sm'
    });
    setForm(<Update classes={classes} scan={data} closedModalDialog={closedModalDialog}/>)
  };

  return (loading ? <Loading/> :
    <Fragment>
      <Modal
        maxWidth={modalState.maxWidth}
        open={modalState.open}
        title={modalState.title}
        onCloseModal={closedModalDialog}
        contentModal={Form}
      />
      <div className={classes.root}>
        <div className={classes.row}>
          <Grid container justify={"space-between"}>
            <Grid item>
              <div className={classes.tagMenu}>
                Dashboard
              </div>
              <div>
                <Breadcrumbs aria-label="breadcrumb">
                  <Typography color="textPrimary">Dashboard</Typography>
                </Breadcrumbs>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className={classes.row}>
          <Grid container spacing={4}>
            <Grid item xl={4} md={4} sm={12} xs={12}>
              <Paper className={classes.panelPadding}>
                <Grid container spacing={2}>
                  <Grid item xl={4} md={4} sm={4} xs={4}>
                    <div className={classes.warningBackrond}>
                      <CropFree style={{width: "90%", height:"auto"}}/>
                    </div>
                  </Grid>
                  <Grid item xl={8} md={8} sm={8} xs={8}>
                    <div className={classes.textScan}>
                      <div className={classes.flexContainer}>
                        <div className={classes.numberScan}>
                          {
                            !isEmpty(scans) ?
                              scans.not_scanned :
                              0
                          }
                        </div>
                        <div className={classes.statusScan}>
                          Not Scaned
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xl={4} md={4} sm={12} xs={12}>
              <Paper className={classes.panelPadding}>
                <Grid container spacing={2}>
                  <Grid item xl={4} md={4} sm={4} xs={4}>
                    <div className={classes.successBackground}>
                      <CropFree style={{width: "90%", height:"auto"}}/>
                    </div>
                  </Grid>
                  <Grid item xl={8} md={8} sm={8} xs={8}>
                    <div className={classes.textScan}>
                      <div className={classes.flexContainer}>
                        <div className={classes.numberScan}>
                          {
                            !isEmpty(scans) ?
                              scans.valid :
                              0
                          }
                        </div>
                        <div className={classes.statusScan}>
                          Scan Valid
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xl={4} md={4} sm={12} xs={12}>
              <Paper className={classes.panelPadding}>
                <Grid container spacing={2}>
                  <Grid item xl={4} md={4} sm={4} xs={4}>
                    <div className={classes.errorBackground}>
                      <CropFree style={{width: "90%", height:"auto"}}/>
                    </div>
                  </Grid>
                  <Grid item xl={8} md={8} sm={8} xs={8}>
                    <div className={classes.textScan}>
                      <div className={classes.flexContainer}>
                        <div className={classes.numberScan}>
                          {
                            !isEmpty(scans) ?
                              scans.invalid :
                              0
                          }
                        </div>
                        <div className={classes.statusScan}>
                          Scan Invalid
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </div>

        <div className={classes.row}>
          <Paper className={classes.panelPadding}>
            <div>
              <Grid container spacing={2}>
                <Grid item xl={2}  md={2} sm={12} xs={12}>
                  <FormControl className={classes.formControl} fullWidth>
                    <InputLabel shrink htmlFor="age-native-label-placeholder">Status</InputLabel>
                    <Select
                      fullWidth
                      native
                      defaultValue={formSearch.status}
                      onChange={handleSearch}
                      label="Status"
                      inputProps={{
                        name: 'status',
                        id: 'age-native-label-placeholder'
                      }}
                      name="status"
                      // onChange={handleChange}
                    >
                      {/*<option value={''}></option>*/}
                      <option value={''}>Semua</option>
                      <option value={'not_scanned'}>Not Scanned</option>
                      <option value={'valid'}>Valid</option>
                      <option value={'invalid'}>Invalid</option>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xl={2}  md={2} sm={12} xs={12}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <TextField
                      onClick={() => setIsOpen(true)}
                      value={moment(startDate.startDate).format('dddd DD MMMM YYYY')}
                      fullWidth
                      className={classes.margin}
                      label="Tanggal Awal"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end" disablePointerEvents>
                            <CalendarToday />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <DatePicker
                      fullWidth
                      open={isOpen}
                      onOpen={() => setIsOpen(true)}
                      onClose={() => setIsOpen(false)}
                      disableFuture
                      // minDate={minDate}
                      variant="outlined"
                      name="start_date"
                      format="dd MMMM yyyy HH:mm"
                      value={startDate.startDate}
                      onChange={e => handleStartDate(e, 'endDate')}
                      TextFieldComponent={() => null}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xl={2}  md={2} sm={12} xs={12}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <TextField
                      onClick={() => setIsOpenEnd(true)}
                      value={moment(endDate.endDate).format('dddd DD MMMM YYYY')}
                      fullWidth
                      className={classes.margin}
                      label="Tanggal Akhir"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end" disablePointerEvents>
                            <CalendarToday />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <DatePicker
                      fullWidth
                      open={isOpenEnd}
                      onOpen={() => setIsOpenEnd(true)}
                      onClose={() => setIsOpenEnd(false)}
                      disableFuture
                      // minDate={minDate}
                      variant="outlined"
                      name="end_date"
                      format="dd MMMM yyyy HH:mm"
                      value={endDate.endDate}
                      onChange={handleEndDate}
                      TextFieldComponent={() => null}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>

                <Grid item xl={3}  md={3} sm={12} xs={12}>
                  <FormControl className={classes.formControl} fullWidth>
                    <InputLabel shrink htmlFor="age-native-label-placeholder">Vendor</InputLabel>
                    <Select
                      fullWidth
                      native
                      defaultValue={formSearch.vendor_id}
                      onChange={handleSearch}
                      label="Vendor"
                      inputProps={{
                        name: 'vendor_id',
                        id: 'age-native-label-placeholder'
                      }}
                      name="vendor_id"
                      // onChange={handleChange}
                    >
                      <option value={''}>Semua</option>
                      {!isEmpty(vendors) && (
                        vendors.data.map((vendor) => (
                          <option value={vendor.id}>{vendor.name}</option>
                        ))
                      )}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xl={3}  md={3} sm={12} xs={12}>
                  <FormControl className={classes.formControl} fullWidth>
                    <InputLabel shrink htmlFor="age-native-label-placeholder">Product</InputLabel>
                    <Select
                      fullWidth
                      native
                      defaultValue={formSearch.product_id}
                      onChange={handleSearch}
                      label="Product"
                      inputProps={{
                        name: 'product_id',
                        id: 'age-native-label-placeholder'
                      }}
                      name="product_id"
                      // onChange={handleChange}
                    >
                      {/*<option value={''}></option>*/}
                      <option value={''}>Semua</option>
                      {!isEmpty(allProduct) && (
                        allProduct.data.map((product) => (
                          <option value={product.id}>{product.name} {product.weight} {product.unit}</option>
                        ))
                      )}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container spacing={2} justify={"flex-end"}>
                <Grid item xl={6}  md={6} sm={12} xs={12}>
                  <Paper component="form" className={classes.searchRoot}>
                    <InputBase
                      className={classes.input}
                      placeholder="Cari code gold"
                      inputProps={{ 'aria-label': 'Cari code gold' }}
                      defaultValue={formSearch.search}
                      name={'search'}
                      onChange={handleSearch}
                    />
                    <IconButton className={classes.iconButton} aria-label="search" disabled>
                      <Search />
                    </IconButton>
                  </Paper>
                </Grid>

                {/*<Grid item xl={1}  md={1} sm={12} xs={12}/>*/}

              </Grid>
            </div>
            <div className={classes.row}>
              <hr/>
            </div>
            <div className={classes.row}>
              <List classes={classes} scans={scans} propsHandleChangePage={(page) => handleChangePage(page)} update={(data) => update(data)}/>
            </div>
          </Paper>
        </div>
      </div>
      {/*Dashboard*/}
    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    loading: state.scan.loading,
    scans: state.scan.scans,
    changing: state.scan.changing,
    allProduct: state.product.allProduct,
    vendors: state.vendor.vendors,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onFetchScans: (page, formSerach) => dispatch(actions.fetchScan(page, formSerach)),
    onFetchVendors:() => dispatch(actions.fetchVendors()),
    onGetAllProduct:() => dispatch(actions.getAllProduct()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);