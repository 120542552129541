import React, {Fragment, useState} from "react";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import {Button, Grid} from "@material-ui/core";
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers';
import * as actions from '../../../../../store/actions';
import {connect} from "react-redux";
import {Loading} from "../../../../../components/UI";
import FormHelperText from "@material-ui/core/FormHelperText";

const schema = yup.object().shape({
});

const Form = props => {
  const {
    classes,
    user,
    history,
    onUpdateUser,
    loading,
    errors
  } = props;

  const [formState, setFormState] = useState({
    name: user.name,
    email: user.email,
    platform: user.platform,
    role: user.role
  });

  const handleChange = (event) => {
    const target = event.target.name
    event.persist()

    if(event.target.type === 'checkbox'){
      setFormState((formState) => ({
        ...formState,
        [target]: event.target.checked
      }))
    }else{
      setFormState((formState) => ({
        ...formState,
        [target]: event.target.value
      }))
    }
  }

  const onSubmit = data => {
    // onStoreUser(data,history);
    onUpdateUser(user.id, data, history);
    // console.log(data);
  }

  const { register, handleSubmit } = useForm({
    resolver: yupResolver(schema)
  })

  let optionRole = '';

  if (formState.platform === 'mobile'){
    optionRole = (
      <Fragment>
        <option aria-label="None" value="" />
        <option value={'scan'}>Scan</option>
      </Fragment>
    )
  }else if(formState.platform === 'web'){
    optionRole = (
      <Fragment>
        <option aria-label="None" value="" />
        <option value={'admin'}>Admin</option>
        <option value={'super_admin'}>Super Admin</option>
      </Fragment>
    )
  }else {
    optionRole = (
      <Fragment>
        <option aria-label="None" value="" />
      </Fragment>
    )
  }

  return (loading ? <Loading/> :
    <Fragment>
      <div className={classes.row}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Paper className={classes.root}>
            <TextField
              className={classes.marginForm}
              label="Nama User"
              variant={"outlined"}
              name="name"
              defaultValue={formState.name}
              onChange={handleChange}
              size={"small"}
              fullWidth
              inputRef={register}
              placeholder={"Masukan Nama User"}
              error={errors.name ? true : false}
              helperText={errors.name && errors.name[0]}
            />

            <TextField
              className={classes.marginForm}
              label="Email"
              variant={"outlined"}
              name="email"
              defaultValue={formState.email}
              onChange={handleChange}
              size={"small"}
              fullWidth
              inputRef={register}
              placeholder={"Masukan Email"}
              error={errors.email ? true : false}
              helperText={errors.email && errors.email[0]}
            />

            <div>
              <FormControl
                error={errors.platform ? true : false}
                variant="outlined" fullWidth size={"small"} className={classes.marginForm}>
                <InputLabel htmlFor="outlined-age-native-simple">Akses</InputLabel>
                <Select
                  fullWidth
                  native
                  defaultValue={formState.platform}
                  onChange={handleChange}
                  label="Akses"
                  inputProps={{
                    name: 'platform',
                    id: 'outlined-age-native-simple'
                  }}
                  name="platform"
                  inputRef={register}
                  // onChange={handleChange}
                >
                  <option aria-label="None" value="" />
                  <option value={'mobile'}>Mobile</option>
                  <option value={'web'}>Web Admin</option>
                </Select>
                <FormHelperText>{errors.platform && errors.platform[0]}</FormHelperText>
              </FormControl>
            </div>

            <div>
              <FormControl
                error={errors.role ? true : false}
                variant="outlined" fullWidth size={"small"}  className={classes.marginForm}>
                <InputLabel htmlFor="outlined-age-native-simple">Role</InputLabel>
                <Select
                  fullWidth
                  native
                  defaultValue={formState.role}
                  onChange={handleChange}
                  label="Role"
                  inputProps={{
                    name: 'role',
                    id: 'outlined-age-native-simple'
                  }}
                  name="role"
                  inputRef={register}
                  // onChange={handleChange}
                >
                  {optionRole}
                </Select>
                <FormHelperText>{errors.role && errors.role[0]}</FormHelperText>
              </FormControl>
            </div>

            <Grid container justify={"flex-end"}>
              <Grid item>
                <Button variant={"contained"} size={"medium"} className={classes.btnWarning} type={"submit"}>
                  Update
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </form>
      </div>
    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    loading: state.user.loading,
    errors: state.user.errors
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUpdateUser: (id, storeData, history) => dispatch(actions.updateUser(id, storeData, history))
  };
};

export default connect(mapStateToProps,mapDispatchToProps)(Form);