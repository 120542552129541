import React, {Fragment, useEffect} from "react";
import {Main} from '../../style';
import {Breadcrumbs, Link, Typography} from "@material-ui/core";
import * as actions from '../../../../store/actions';
import {connect} from "react-redux";
import {useHistory, useParams} from 'react-router-dom';
import {Loading} from "../../../../components/UI";
import {CustomRouterLink, isEmpty} from "../../../../shared/utility";
import Form from "./Form/Form";
// import FormHelperText from "@material-ui/core/FormHelperText";

const Update = props => {
  const classes = Main();
  const {id} = useParams();
  const history = useHistory();
  const {
    loading,
    onGetProduct,
    product,
    onClearErrorProduct
  } = props;

  // useEffect(() => {
  //   onClearErrorProduct()
  // },[onClearErrorProduct])

  useEffect(() => {
    onGetProduct(id)
    onClearErrorProduct()
  }, [onGetProduct,id,onClearErrorProduct])

  return (loading ? <Loading/> :
      <Fragment>
        <div className={classes.root}>
          <div className={classes.row}>
            <div>
              <div className={classes.tagMenu}>
                Management Product
              </div>
              <div>
                <Breadcrumbs aria-label="breadcrumb">
                  <Link color="inherit" component={CustomRouterLink} to="/dashboard">
                    Dashboard
                  </Link>
                  <Link color="inherit" component={CustomRouterLink} to="/product">
                    Management Product
                  </Link>
                  <Typography color="textPrimary">Update Product</Typography>
                </Breadcrumbs>
              </div>
            </div>
          </div>
          <div className={classes.row}>
            {!isEmpty(product) && (
              <Form classes={classes} product={product.data} history={history}/>
            )}
          </div>
        </div>
      </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    loading: state.product.loading,
    errors: state.product.errors,
    product: state.product.product
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetProduct:(id) => dispatch(actions.getProduct(id)),
    onClearErrorProduct: () => dispatch(actions.clearErrorProduct())
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(Update);