import React, {Fragment, useEffect} from "react";
import * as actions from '../../../../store/actions';
import {connect} from "react-redux";
import {useParams, useHistory} from 'react-router-dom';
import {Loading} from "../../../../components/UI";
import {CustomRouterLink, isEmpty} from "../../../../shared/utility";
import {Main} from "../../style";
import {Breadcrumbs, Grid, Link, Typography} from "@material-ui/core";
import Form from "./Form/Form";

const Update = props => {
  const classes = Main();
  const {
    onGetUser,
    user,
    loading
  } = props;
  const {id} = useParams();
  const history = useHistory();

  useEffect(() => {
    onGetUser(id)
  },[onGetUser,id]);

  return (loading ? <Loading/> :
    <Fragment>
      <div className={classes.root}>
        <div className={classes.row}>
          <Grid container justify={"space-between"}>
            <Grid item>
              <div className={classes.tagMenu}>
                Management User
              </div>
              <div>
                <Breadcrumbs aria-label="breadcrumb">
                  <Link color="inherit" component={CustomRouterLink} to="/dashboard">
                    Dashboard
                  </Link>
                  <Link color="inherit" component={CustomRouterLink} to="/management-user">
                    Management User
                  </Link>
                  <Typography color="textPrimary">Update User</Typography>
                </Breadcrumbs>
              </div>
            </Grid>
          </Grid>
        </div>
        {!isEmpty(user) && (
          <Form classes={classes} user={user} history={history}/>
        )}
      </div>
      {/*Update*/}
    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    user: state.user.user,
    loading: state.user.loading
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetUser: (id) => dispatch(actions.getUser(id))
  };
};

export default connect(mapStateToProps,mapDispatchToProps)(Update);