import React,{Fragment} from "react";
import {IconButton, TableCell, TableRow, Tooltip} from "@material-ui/core";
import moment from "moment";
import clsx from "clsx";
import {Edit} from "@material-ui/icons";

const DataScan = props => {
  const {
    scan,
    classes,
    edited
  } = props;

  const status = (
    scan.status === 'valid' ? (
      <div className={clsx(classes.textSuccess,classes.textCapitalize)}>
        Valid
      </div>
    ) : scan.status === 'not_scanned' ? (
      <div className={clsx(classes.textWarning,classes.textCapitalize)}>
        Not Scanned
      </div>
    ) : scan.status === 'invalid' ? (
      <div className={clsx(classes.textError,classes.textCapitalize)}>
        Invalid
      </div>
    ) : '');

  return (
    <Fragment>
      <TableRow key={scan.id}>
        <TableCell>
          {scan.product && `${scan.product.name} ${scan.product.weight} ${scan.product.unit}`}
        </TableCell>
        <TableCell>
          {scan.code}
        </TableCell>
        <TableCell className={classes.textCapitalize}>
          {scan.vendor_name}
        </TableCell>
        <TableCell>
          {status}
        </TableCell>
        <TableCell className={classes.textCapitalize}>
          {scan.user && scan.user.name}
        </TableCell>
        <TableCell>
          {scan.last_scan_time ? (
            <Fragment>
              <div>{moment(scan.last_scan_time).format('DD MMMM YYYY')}</div>
              <div style={{fontSize: '12px'}}>{moment(scan.last_scan_time).format('HH:mm')}</div>
            </Fragment>
          ) : '-'}
        </TableCell>
        <TableCell>
          {scan.status === 'not_scanned' && (
            <Tooltip title="Edit">
              <IconButton aria-label="edit" className={classes.textPrimary} onClick={() => edited(scan)}>
                <Edit />
              </IconButton>
            </Tooltip>
          )}
        </TableCell>
      </TableRow>
    </Fragment>
  );
};

export default DataScan;