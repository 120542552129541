import * as actions from './actionTypes'
import axios from '../../axios-orders'
import { setAlert } from './alert'

export const fetchVendorsStart = () => {
  return {
    type: actions.FETCH_VENDORS_START
  }
}

export const fetchVendorsSuccess = (vendors) => {
  return {
    type: actions.FETCH_VENDORS_SUCCESS,
    vendors: vendors
  }
}

export const fetchVendorsFail = (errors) => {
  return {
    type: actions.FETCH_VENDORS_FAIL,
    errors: errors
  }
}

export const fetchVendors = () => {
  return dispatch => {
    dispatch(fetchVendorsStart())

    axios.get(`/api/admin/vendor`, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`
      }
    })
      .then(response => {
        dispatch(fetchVendorsSuccess(response.data))
      })
      .catch(err => {
        dispatch(fetchVendorsFail(err.response.data.message))
        dispatch(setAlert(err.response.data.message, "error"))
      })
  }
}

export const storeVendorStart = () => {
  return {
    type: actions.STORE_VENDOR_START
  }
}

export const storeVendorSuccess = (contentDialog) => {
  return {
    type: actions.STORE_VENDOR_SUCCESS,
    contentDialog: contentDialog
  }
}

export const storeVendorFail = (errors) => {
  return {
    type: actions.STORE_VENDOR_FAIL,
    errors: errors
  }
}

export const storeVendor = (storeData,history) => {
  return dispatch => {
    dispatch(storeVendorStart())
    axios.post(`api/admin/vendor`,storeData, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`
      }
    })
      .then(response => {
        dispatch(setAlert(response.data.message, 'success'))
        dispatch(storeVendorSuccess(response.data))
        history.push(`/vendor`);
      })
      .catch(error => {
        dispatch(storeVendorFail(error.response.data.errors ? error.response.data.errors : {}))
        dispatch(setAlert(error.response.data.message, 'error'))
      })
  }
}

export const updateVendorStart = () => {
  return {
    type: actions.UPDATE_VENDOR_START
  }
}

export const updateVendorSuccess = () => {
  return {
    type: actions.UPDATE_VENDOR_SUCCESS
  }
}

export const updateVendorFail = (errors) => {
  return {
    type: actions.UPDATE_VENDOR_FAIL,
    errors: errors
  }
}

export const updateVendor = (id,storeData) => {
  return dispatch => {
    dispatch(updateVendorStart())
    axios.put(`api/admin/vendor/${id}`,storeData, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`
      }
    })
      .then(response => {
        dispatch(setAlert(response.data.message, 'success'))
        dispatch(updateVendorSuccess(response.data))
        // history.push(`/vendor`);
      })
      .catch(error => {
        dispatch(updateVendorFail(error.response.data.errors ? error.response.data.errors : {}))
        dispatch(setAlert(error.response.data.message, 'error'))
      })
  }
}

export const deleteVendorStart = () => {
  return {
    type: actions.DELETE_VENDOR_START
  }
}

export const deleteVendorSuccess = () => {
  return {
    type: actions.DELETE_VENDOR_SUCCESS
  }
}

export const deleteVendorFail = (errors) => {
  return {
    type: actions.DELETE_VENDOR_FAIL,
    errors: errors
  }
}

export const deleteVendor = (id) => {
  return dispatch => {
    dispatch(deleteVendorStart())
    axios.delete(`api/admin/vendor/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`
      }
    })
      .then(response => {
        dispatch(setAlert(response.data.message, 'success'))
        dispatch(deleteVendorSuccess(response.data))
        // history.push(`/vendor`);
      })
      .catch(error => {
        dispatch(deleteVendorFail(error.response.data.message ? error.response.data.message : {}))
        dispatch(setAlert(error.response.data.message, 'error'))
      })
  }
}

export const clearErrorVendor = () => {
  return {
    type: actions.CLEAR_ERROR_VENDOR
  }
}