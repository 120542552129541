import React, { Fragment, useState } from "react";
import { Main } from '../../style';
import { Breadcrumbs, Button, Grid, Link, Typography } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
// import Visibility from '@material-ui/icons/Visibility';
// import VisibilityOff from '@material-ui/icons/VisibilityOff';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import * as actions from '../../../../store/actions';
import { connect } from "react-redux";
import { useHistory } from 'react-router-dom';
import { Loading } from "../../../../components/UI";
import FormHelperText from "@material-ui/core/FormHelperText";
import { CustomRouterLink } from "../../../../shared/utility";
import {LockOpenRounded, LockRounded} from "@material-ui/icons";

const schema = yup.object().shape({
});

const Create = props => {
  const classes = Main();
  const history = useHistory();
  const {
    onStoreUser,
    loading,
    errors
  } = props;

  const [formState, setFormState] = useState({
    name: '',
    email: '',
    password: '',
    platform: '',
    role: '',
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setFormState({ ...formState, showPassword: !formState.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChange = (event) => {
    const target = event.target.name
    event.persist()

    if (event.target.type === 'checkbox') {
      setFormState((formState) => ({
        ...formState,
        [target]: event.target.checked
      }))
    } else {
      setFormState((formState) => ({
        ...formState,
        [target]: event.target.value
      }))
    }
  }

  const { register, handleSubmit } = useForm({
    resolver: yupResolver(schema)
  })

  const onSubmit = data => {
    onStoreUser(data, history);
  }

  let optionRole = '';

  if (formState.platform === 'mobile') {
    optionRole = (
      <Fragment>
        <option aria-label="None" value="" />
        <option value={'scan'}>Scan</option>
      </Fragment>
    )
  } else if (formState.platform === 'web') {
    optionRole = (
      <Fragment>
        <option aria-label="None" value="" />
        <option value={'admin'}>Admin</option>
        <option value={'super_admin'}>Super Admin</option>
      </Fragment>
    )
  } else {
    optionRole = (
      <Fragment>
        <option aria-label="None" value="" />
      </Fragment>
    )
  }

  return (loading ? <Loading /> :
    <Fragment>
      <div className={classes.root}>
        <div className={classes.row}>
          <div>
            <div className={classes.tagMenu}>
              Management User
            </div>
            <div>
              <Breadcrumbs aria-label="breadcrumb">
                <Link color="inherit" component={CustomRouterLink} to="/dashboard">
                  Dashboard
                </Link>
                <Link color="inherit" component={CustomRouterLink} to="/management-user">
                  Management User
                </Link>
                <Typography color="textPrimary">Create User</Typography>
              </Breadcrumbs>
            </div>
          </div>
        </div>
        <div className={classes.row}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Paper className={classes.root}>
              <TextField
                className={classes.marginForm}
                label="Nama User"
                variant={"outlined"}
                name="name"
                defaultValue={formState.name}
                onChange={handleChange}
                size={"small"}
                fullWidth
                inputRef={register}
                placeholder={"Masukan Nama User"}
                error={errors.name ? true : false}
                helperText={errors.name && errors.name[0]}
              />

              <TextField
                className={classes.marginForm}
                label="Email"
                variant={"outlined"}
                name="email"
                defaultValue={formState.email}
                onChange={handleChange}
                size={"small"}
                fullWidth
                inputRef={register}
                placeholder={"Masukan Email"}
                error={errors.email ? true : false}
                helperText={errors.email && errors.email[0]}
              />

              <TextField
                className={classes.marginForm}
                label="Password"
                variant={"outlined"}
                name="password"
                defaultValue={formState.password}
                onChange={handleChange}
                size={"small"}
                fullWidth
                inputRef={register}
                type={formState.showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment:
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {formState.showPassword ? <LockOpenRounded /> : <LockRounded />}
                      </IconButton>
                    </InputAdornment>,
                }}
                placeholder={"Masukan Password"}
                error={errors.password ? true : false}
                helperText={errors.password && errors.password[0]}
              />

              <div>
                <FormControl
                  error={errors.platform ? true : false}
                  variant="outlined" fullWidth size={"small"} className={classes.marginForm}>
                  <InputLabel htmlFor="outlined-age-native-simple">Akses</InputLabel>
                  <Select
                    fullWidth
                    native
                    defaultValue={formState.platform}
                    onChange={handleChange}
                    label="Akses"
                    inputProps={{
                      name: 'platform',
                      id: 'outlined-age-native-simple'
                    }}
                    name="platform"
                    inputRef={register}
                  // onChange={handleChange}
                  >
                    <option aria-label="None" value="" />
                    <option value={'mobile'}>Mobile</option>
                    <option value={'web'}>Web Admin</option>
                  </Select>
                  <FormHelperText>{errors.platform && errors.platform[0]}</FormHelperText>
                </FormControl>
              </div>

              <div>
                <FormControl
                  error={errors.role ? true : false}
                  variant="outlined" fullWidth size={"small"} className={classes.marginForm}>
                  <InputLabel htmlFor="outlined-age-native-simple">Role</InputLabel>
                  <Select
                    fullWidth
                    native
                    defaultValue={formState.role}
                    onChange={handleChange}
                    label="Role"
                    inputProps={{
                      name: 'role',
                      id: 'outlined-age-native-simple'
                    }}
                    name="role"
                    inputRef={register}
                  // onChange={handleChange}
                  >
                    {optionRole}
                  </Select>
                  <FormHelperText>{errors.role && errors.role[0]}</FormHelperText>
                </FormControl>
              </div>

              <Grid container justify={"flex-end"}>
                <Grid item>
                  <Button variant={"contained"} size={"medium"} className={classes.btnSuccess} type={"submit"}>
                    Simpan
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    loading: state.user.loading,
    errors: state.user.errors
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onStoreUser: (storeData, history) => dispatch(actions.storeUser(storeData, history))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Create);