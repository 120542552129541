import React, {Fragment, useEffect,useState} from "react";
import {
  Main
} from './style';
import {Button, Grid,Link,Breadcrumbs, Typography} from "@material-ui/core";
import {StorefrontOutlined} from "@material-ui/icons";
import {Delete, List, Update} from "./compenents";
import * as actions from '../../store/actions';
import {connect} from "react-redux";
import {Loading} from "../../components/UI";
import {CustomRouterLink} from "../../shared/utility";
import {Modal} from '../../components/UI'
// import {useHistory} from 'react-router-dom'

const ManagementUser = props => {
  const {
    onFetchVendors,
    loading,
    changing,
    onDeleteVendor
  } = props;
  const classes = Main();

  useEffect(() => {
    onFetchVendors()
    closedModalDialog()
  },[onFetchVendors,changing]);

  const [Form, setForm] = useState('');
  const [modalState, setModalState] = useState({
    open: false,
    title: '',
    maxWidth: 'sm',
  });
  const closedModalDialog = () => {
    setModalState({
      maxWidth: 'sm',
      title: '',
      open: false,
    });
    setForm('');
  };

  const deleteData = data => {
    console.log(data);
    setModalState({
      maxWidth: 'sm',
      title: 'Delete Vendor',
      open: true,
    });
    setForm(<Delete data={data} classes={classes} closedModalDialog={() => closedModalDialog()} deleted={(id) => deleted(id)}/>);
  }

  const deleted = id => {
    onDeleteVendor(id)
  }

  const updateData = data => {
    console.log(data);
    setModalState({
      maxWidth: 'sm',
      title: 'Update Vendor',
      open: true,
    });
    setForm(<Update classes={classes} vendor={data} closedModalDialog={() => closedModalDialog()}/>);
  }

  return (loading ? <Loading/> :
      <Fragment>
        <Modal
          maxWidth={modalState.maxWidth}
          open={modalState.open}
          title={modalState.title}
          onCloseModal={closedModalDialog}
          contentModal={Form}
        />
        <div className={classes.root}>
          <div className={classes.row}>
            <Grid container justify={"space-between"}>
              <Grid item>
                <div>
                  <div className={classes.tagMenu}>
                    Management Vendor
                  </div>
                  <div>
                    <Breadcrumbs aria-label="breadcrumb">
                      <Link color="inherit" component={CustomRouterLink} to="/dashboard">
                        Dashboard
                      </Link>
                      <Typography color="textPrimary">Management Vendor</Typography>
                    </Breadcrumbs>
                  </div>
                </div>

              </Grid>
              <Grid item>
                <Button
                  variant={"contained"}
                  size={"medium"}
                  startIcon={React.cloneElement(<StorefrontOutlined/>)}
                  className={classes.btnWarning}
                  component={CustomRouterLink}
                  to={'/vendor-create'}
                >
                  Tambah Vendor
                </Button>
              </Grid>
            </Grid>
          </div>

          <div className={classes.row}>
            <List classes={classes} deleteProps={(data) => deleteData(data)} updateProps={(data) => updateData(data)}/>
          </div>
        </div>
      </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    vendors: state.vendor.vendors,
    loading: state.vendor.loading,
    changing: state.vendor.changing
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchVendors: () => dispatch(actions.fetchVendors()),
    onDeleteVendor: (id) => dispatch(actions.deleteVendor(id))
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(ManagementUser);