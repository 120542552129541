import * as actions from './actionTypes'
import axios from '../../axios-orders'
import { setAlert } from './alert'

export const fetchUsersStart = () => {
  return {
    type: actions.FETCH_USERS_START
  }
}

export const fetchUsersSuccess = (users) => {
  return {
    type: actions.FETCH_USERS_SUCCESS,
    users: users
  }
}

export const fetchUsersFail = (errors) => {
  return {
    type: actions.FETCH_USERS_FAIL,
    errors: errors
  }
}

export const fetchUsers = (page) => {
  return dispatch => {
    dispatch(fetchUsersStart())

    axios.get(`api/admin/user?page=${page}`, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`
      }
    })
      .then(response => {
        dispatch(fetchUsersSuccess(response.data))
      })
      .catch(err => {
        dispatch(fetchUsersFail(err.response.data.message))
        dispatch(setAlert(err.response.data.message, "error"))
      })
  }
}

export const getUserStart = () => {
  return {
    type: actions.GET_USER_START
  }
}

export const getUserSuccess = (user) => {
  return {
    type: actions.GET_USER_SUCCESS,
    user: user
  }
}

export const getUserFail = (errors) => {
  return {
    type: actions.GET_USER_FAIL,
    errors: errors
  }
}

export const getUser = (id) => {
  return dispatch => {
    dispatch(getUserStart())

    axios.get(`api/admin/user/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`
      }
    })
      .then(response => {
        dispatch(getUserSuccess(response.data.data))
      })
      .catch(err => {
        dispatch(getUserFail(err.response.data.message))
        dispatch(setAlert(err.response.data.message, "error"))
      })
  }
}

export const storeUserStart = () => {
  return {
    type: actions.STORE_USER_START
  }
}

export const storeUserSuccess = (users) => {
  return {
    type: actions.STORE_USER_SUCCESS,
    users: users
  }
}

export const storeUserFail = (errors) => {
  return {
    type: actions.STORE_USER_FAIL,
    errors: errors
  }
}

export const storeUser = (storeData,history) => {
  return dispatch => {
    dispatch(storeUserStart())

    axios.post(`api/admin/user`,storeData, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`
      }
    })
      .then(response => {
        dispatch(storeUserSuccess(response.data.message))
        dispatch(setAlert(response.data.message, "success"))
        history.push(`/management-user`);
      })
      .catch(err => {
        dispatch(storeUserFail(err.response.data.errors ? err.response.data.errors : {}))
        dispatch(setAlert(err.response.data.message, "error"))
      })
  }
}

export const updateUserStart = () => {
  return {
    type: actions.UPDATE_USER_START
  }
}

export const updateUserSuccess = (users) => {
  return {
    type: actions.UPDATE_USER_SUCCESS,
    users: users
  }
}

export const updateUserFail = (errors) => {
  return {
    type: actions.UPDATE_USER_FAIL,
    errors: errors
  }
}

export const updateUser = (id,storeData,history) => {
  return dispatch => {
    dispatch(updateUserStart())

    axios.put(`api/admin/user/${id}`,storeData, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`
      }
    })
      .then(response => {
        dispatch(updateUserSuccess(response.data.message))
        dispatch(setAlert(response.data.message, "success"))
        history.push(`/management-user`);
      })
      .catch(err => {
        dispatch(updateUserFail(err.response.data.errors ? err.response.data.errors : {}))
        dispatch(setAlert(err.response.data.message, "error"))
      })
  }
}

export const deleteUserStart = () => {
  return {
    type: actions.DELETE_USER_START
  }
}

export const deleteUserSuccess = (users) => {
  return {
    type: actions.DELETE_USER_SUCCESS,
  }
}

export const deleteUserFail = (errors) => {
  return {
    type: actions.DELETE_USER_FAIL,
    errors: errors
  }
}

export const deleteUser = (id,history) => {
  return dispatch => {
    dispatch(deleteUserStart())

    axios.delete(`api/admin/user/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`
      }
    })
      .then(response => {
        dispatch(deleteUserSuccess(response.data.message))
        dispatch(setAlert(response.data.message, "success"))
        history.push(`/management-user`);
      })
      .catch(err => {
        dispatch(deleteUserFail(err.response.data.message))
        dispatch(setAlert(err.response.data.message, "error"))
      })
  }
}