import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../../shared/utility'

const initialState = {
  token: null,
  user: {},
  changing: 0,
  errors: {},
  loading: false,
  // authRedirectPath: '/dashboard'
}

const getProfileStart = (state, action) => {
  return updateObject(state, { loading: true })
}

const getProfileSuccess = (state, action) => {
  return updateObject(state, {
    user: action.user,
    errors: {},
    loading: false
  })
}

const getProfileFail = (state, action) => {
  return updateObject(state, { errors: action.errors, loading: false })
}

const updateProfileNameStart = (state, action) => {
  return updateObject(state, { loading: true })
}

const updateProfileNameSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    changing: state.changing+1
  })
}

const updateProfilePasswordFail = (state, action) => {
  return updateObject(state, { errors: action.errors, loading: false })
}

const updateProfilePasswordStart = (state, action) => {
  return updateObject(state, { loading: true })
}

const updateProfilePasswordSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    changing: state.changing+1
  })
}

const updateProfileNameFail = (state, action) => {
  return updateObject(state, { errors: action.errors, loading: false })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_PROFILE_START: return getProfileStart(state, action)
    case actionTypes.GET_PROFILE_SUCCESS: return getProfileSuccess(state, action)
    case actionTypes.GET_PROFILE_FAIL: return getProfileFail(state, action)
    case actionTypes.UPDATE_PROFILE_NAME_START: return updateProfileNameStart(state, action)
    case actionTypes.UPDATE_PROFILE_NAME_SUCCESS: return updateProfileNameSuccess(state, action)
    case actionTypes.UPDATE_PROFILE_NAME_FAIL: return updateProfileNameFail(state, action)
    case actionTypes.UPDATE_PROFILE_PASSWORD_START: return updateProfilePasswordStart(state, action)
    case actionTypes.UPDATE_PROFILE_PASSWORD_SUCCESS: return updateProfilePasswordSuccess(state, action)
    case actionTypes.UPDATE_PROFILE_PASSWORD_FAIL: return updateProfilePasswordFail(state, action)

    default: return state
  }
}

export default reducer