import React,{Fragment} from "react";
import {TableCell, TableRow, IconButton, Tooltip} from "@material-ui/core";
import {Delete, Edit} from "@material-ui/icons";

const PurchaseOrderData = props => {
  const {user, index,classes,deleted,edited} = props;
  const role = (
    user.role ==='scan' ? 'Scan' :
      user.role ==='admin' ? 'Admin' :
        user.role === 'super_admin' ? 'Super Admin' :
          ''
  );

  return (
    <Fragment>
      <TableRow key={user.id}>
        <TableCell>
          {index+1}
        </TableCell>
        <TableCell className={classes.textCapitalize}>
          {user.name}
        </TableCell>
        <TableCell>
          {user.email}
        </TableCell>
        <TableCell className={classes.textCapitalize}>
          {user.platform}
        </TableCell>
        <TableCell className={classes.textCapitalize}>
          {role}
        </TableCell>
        <TableCell>
          <Tooltip title="Edit User">
            <IconButton aria-label="edit" className={classes.textPrimary} onClick={() => edited(user)}>
              <Edit />
            </IconButton>
          </Tooltip>
          <Tooltip title="Hapus User">
            <IconButton aria-label="hapus" className={classes.textError} onClick={() => deleted(user)}>
              <Delete />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
    </Fragment>
  );
};

export default PurchaseOrderData;