import * as actions from './actionTypes'
import axios from '../../axios-orders'
import { setAlert } from './alert'

export const getProfileStart = () => {
  return {
    type: actions.GET_PROFILE_START
  }
}

export const getProfileSuccess = (user) => {
  return {
    type: actions.GET_PROFILE_SUCCESS,
    user: user
  }
}

export const getProfileFail = (errors) => {
  return {
    type: actions.GET_PROFILE_FAIL,
    errors: errors
  }
}

export const getProfile = () => {
  return dispatch => {
    dispatch(getProfileStart())

    axios.get(`api/profile/own`, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem("access_token") ? sessionStorage.getItem("access_token"):''}`
      }
    })
      .then(response => {
        dispatch(getProfileSuccess(response.data.data))
      })
      .catch(err => {
        dispatch(getProfileFail(err.response.data.message ? err.response.data.message : ''))
        dispatch(setAlert(err.response.data.message  ? err.response.data.message : '', "error"))
      })
  }
}

export const updateProfileNameStart = () => {
  return {
    type: actions.UPDATE_PROFILE_NAME_START
  }
}

export const updateProfileNameSuccess = (users) => {
  return {
    type: actions.UPDATE_PROFILE_NAME_SUCCESS,
    users: users
  }
}

export const updateProfileNameFail = (errors) => {
  return {
    type: actions.UPDATE_PROFILE_NAME_FAIL,
    errors: errors
  }
}

export const updateProfileName = (storeData) => {
  return dispatch => {
    dispatch(updateProfileNameStart())

    axios.patch(`api/profile/name`,storeData, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`
      }
    })
      .then(response => {
        dispatch(updateProfileNameSuccess(response.data.message))
        dispatch(setAlert(response.data.message, "success"))
        // history.push(`/management-user`);
      })
      .catch(err => {
        dispatch(updateProfileNameFail(err.response.data.errors ? err.response.data.errors : {}))
        dispatch(setAlert(err.response.data.message, "error"))
      })
  }
}

export const updateProfilePasswordStart = () => {
  return {
    type: actions.UPDATE_PROFILE_PASSWORD_START
  }
}

export const updateProfilePasswordSuccess = (users) => {
  return {
    type: actions.UPDATE_PROFILE_PASSWORD_SUCCESS,
    users: users
  }
}

export const updateProfilePasswordFail = (errors) => {
  return {
    type: actions.UPDATE_PROFILE_PASSWORD_FAIL,
    errors: errors
  }
}

export const updateProfilePassword = (storeData) => {
  return dispatch => {
    dispatch(updateProfilePasswordStart())

    axios.patch(`api/profile/password`,storeData, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`
      }
    })
      .then(response => {
        dispatch(updateProfilePasswordSuccess(response.data.message))
        dispatch(setAlert(response.data.message, "success"))
        // history.push(`/management-user`);
      })
      .catch(err => {
        dispatch(updateProfilePasswordFail(err.response.data.errors ? err.response.data.errors : {}))
        dispatch(setAlert(err.response.data.message, "error"))
      })
  }
}