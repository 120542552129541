import React, { Fragment, useState } from "react";
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import LockOpenRounded from '@material-ui/icons/LockOpenRounded';
import LockRounded from '@material-ui/icons/LockRounded';
import * as actions from '../../../../store/actions';
import { connect } from "react-redux";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import TextField from "@material-ui/core/TextField";
import { Button, Grid } from "@material-ui/core";
import { Loading } from "../../../../components/UI";

const schema = yup.object().shape({
});

const FormChangePassword = props => {
  const {
    classes,
    onUpdateProfilePassword,
    loading,
    errors
  } = props;
  const [formState, setFormState] = useState({
    old_password: '',
    new_password: '',
    confirmation_password: '',
    showOldPassword: false,
    showNewPassword: false,
    showConfirmationPassword: false,
  });

  const handleClickShowOldPassword = () => {
    setFormState({ ...formState, showOldPassword: !formState.showOldPassword });
  };

  const handleClickShowNewPassword = () => {
    setFormState({ ...formState, showNewPassword: !formState.showNewPassword });
  };

  const handleClickShowConfirmationPassword = () => {
    setFormState({ ...formState, showConfirmationPassword: !formState.showConfirmationPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChange = (event) => {
    const target = event.target.name
    event.persist()

    if (event.target.type === 'checkbox') {
      setFormState((formState) => ({
        ...formState,
        [target]: event.target.checked
      }))
    } else {
      setFormState((formState) => ({
        ...formState,
        [target]: event.target.value
      }))
    }
  }

  const { register, handleSubmit } = useForm({
    resolver: yupResolver(schema)
  })

  const onSubmit = data => {
    onUpdateProfilePassword(data)
    // onStoreUser(data,history);
  }
  return (loading ? <Loading /> :
    <Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xl={4} md={4} sm={12} xs={12}>
            <TextField
              // className={classes.marginForm}
              label="Password Lama"
              variant={"outlined"}
              name="old_password"
              defaultValue={formState.old_password}
              onChange={handleChange}
              size={"small"}
              fullWidth
              inputRef={register}
              placeholder={"Masukan Password Lama"}
              type={formState.showOldPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment:
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowOldPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {formState.showOldPassword ? <LockOpenRounded /> : <LockRounded />}
                    </IconButton>
                  </InputAdornment>,
              }}
              error={errors.old_password ? true : false}
              helperText={errors.old_password && errors.old_password[0]}
            />
          </Grid>
          <Grid item xl={4} md={4} sm={12} xs={12}>
            <TextField
              // className={classes.marginForm}
              label="Password Baru"
              variant={"outlined"}
              name="new_password"
              defaultValue={formState.new_password}
              onChange={handleChange}
              size={"small"}
              fullWidth
              inputRef={register}
              placeholder={"Masukan Password Baru"}
              type={formState.showNewPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment:
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowNewPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {formState.showNewPassword ? <LockOpenRounded /> : <LockRounded />}
                    </IconButton>
                  </InputAdornment>,
              }}
              error={errors.new_password ? true : false}
              helperText={errors.new_password && errors.new_password[0]}
            />
          </Grid>
          <Grid item xl={4} md={4} sm={12} xs={12}>
            <TextField
              // className={classes.marginForm}
              label="Konfirmasi Password"
              variant={"outlined"}
              name="confirmation_password"
              defaultValue={formState.confirmation_password}
              onChange={handleChange}
              size={"small"}
              fullWidth
              inputRef={register}
              placeholder={"Masukan Konfirmasi Password"}
              type={formState.showConfirmationPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment:
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmationPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {formState.showConfirmationPassword ? <LockOpenRounded /> : <LockRounded />}
                    </IconButton>
                  </InputAdornment>,
              }}
              error={errors.confirmation_password ? true : false}
              helperText={errors.confirmation_password && errors.confirmation_password[0]}
            />
          </Grid>

        </Grid>
        <div style={{ margin: '20px auto' }}>
          <hr />
        </div>
        <Button type={"submit"} variant={"contained"} className={classes.btnWarning} size={"small"}>
          Simpan
        </Button>
      </form>
    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    loading: state.profile.loading,
    errors: state.profile.errors,
    changing: state.profile.changing,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onUpdateProfilePassword: (storeData) => dispatch(actions.updateProfilePassword(storeData))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormChangePassword);