import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../../shared/utility'

const initialState = {
  token: null,
  scans: {},
  scan: {},
  changing: 0,
  errors: {},
  statusDialog: false,
  contentDialog: {},
  loading: false,
  // authRedirectPath: '/dashboard'
}

const fetchScansStart = (state, action) => {
  return updateObject(state, { loading: true })
}

const fetchScansSuccess = (state, action) => {
  return updateObject(state, {
    scans: action.scans,
    loading: false
  })
}

const fetchScansFail = (state, action) => {
  return updateObject(state, { errors: action.errors, loading: false })
}

const storeScanStart = (state, action) => {
  return updateObject(state, { loading: true })
}

const storeScanSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    contentDialog: action.contentDialog,
    statusDialog: true,
    changing: state.changing+1
  })
}

const storeScanFail = (state, action) => {
  return updateObject(state, { errors: action.errors, loading: false })
}

const updateScanStart = (state, action) => {
  return updateObject(state, { loading: true })
}

const updateScanSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    changing: state.changing+1
  })
}

const updateScanFail = (state, action) => {
  return updateObject(state, { errors: action.errors, loading: false })
}

const closedDialog = (state, action) => {
  return updateObject(state,
    {
      statusDialog: false,
      contentDialog: {}
    }
  )
}

const clearError = (state, action) => {
  return updateObject(state, {
    errors: {}
  })
}

const productCheckStart = (state, action) => {
  return updateObject(state, { loading: true })
}

const productCheckSuccess = (state, action) => {
  return updateObject(state, {
    // scan: action.scans,
    loading: false
  })
}

const productCheckFail = (state, action) => {
  return updateObject(state, { errors: action.errors, loading: false })
}

const productDetailStart = (state, action) => {
  return updateObject(state, { loading: true })
}

const productDetailSuccess = (state, action) => {
  return updateObject(state, {
    scan: action.scan,
    loading: false
  })
}

const productDetailFail = (state, action) => {
  return updateObject(state, { errors: action.errors, loading: false })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_SCANS_START: return fetchScansStart(state, action)
    case actionTypes.FETCH_SCANS_SUCCESS: return fetchScansSuccess(state, action)
    case actionTypes.FETCH_SCANS_FAIL: return fetchScansFail(state, action)
    case actionTypes.STORE_SCAN_START: return storeScanStart(state, action)
    case actionTypes.STORE_SCAN_SUCCESS: return storeScanSuccess(state, action)
    case actionTypes.STORE_SCAN_FAIL: return storeScanFail(state, action)
    case actionTypes.UPDATE_SCAN_START: return updateScanStart(state, action)
    case actionTypes.UPDATE_SCAN_SUCCESS: return updateScanSuccess(state, action)
    case actionTypes.UPDATE_SCAN_FAIL: return updateScanFail(state, action)
    case actionTypes.CLOSED_DIALOG: return closedDialog(state, action)
    case actionTypes.CLEAR_ERROR: return clearError(state, action)
    case actionTypes.PRODUCT_CHECK_START: return productCheckStart(state, action)
    case actionTypes.PRODUCT_CHECK_SUCCESS: return productCheckSuccess(state, action)
    case actionTypes.PRODUCT_CHECK_FAIL: return productCheckFail(state, action)
    case actionTypes.GET_PRODUCT_DETAIL_START: return productDetailStart(state, action)
    case actionTypes.GET_PRODUCT_DETAIL_SUCCESS: return productDetailSuccess(state, action)
    case actionTypes.GET_PRODUCT_DETAIL_FAIL: return productDetailFail(state, action)

    default: return state
  }
}

export default reducer