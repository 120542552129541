import React, {Fragment, useEffect, useState} from "react";
import {Main} from '../../style';
import {Button, Grid} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers';
import * as actions from '../../../../store/actions';
import {connect} from "react-redux";
// import {useHistory} from 'react-router-dom';
import {Loading} from "../../../../components/UI";
// import {CustomRouterLink} from "../../../../shared/utility";
// import FormHelperText from "@material-ui/core/FormHelperText";

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const schema = yup.object().shape({
  phone: yup.string().required('No Telepon wajib diisi')
    .matches(phoneRegExp, 'No Telepon tidak valid'),
});

const Update = props => {
  const classes = Main();
  const {
    onUpdateVendor,
    loading,
    errorForm,
    vendor,
    closedModalDialog,
    onClearErrorVendor
  } = props;

  useEffect(() => {
    onClearErrorVendor()
  },[onClearErrorVendor])

  const [formState, setFormState] = useState({
    name: vendor.name,
    address: vendor.address,
    phone: vendor.phone,
    contact: vendor.contact,
  });

  const handleChange = (event) => {
    const target = event.target.name
    event.persist()

    if(event.target.type === 'checkbox'){
      setFormState((formState) => ({
        ...formState,
        [target]: event.target.checked
      }))
    }else{
      setFormState((formState) => ({
        ...formState,
        [target]: event.target.value
      }))
    }
  }

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema)
  })

  const onSubmit = data => {
    onUpdateVendor(vendor.id, data);
  }

  return (loading ? <Loading/> :
    <Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Paper className={classes.root}>
          <TextField
            className={classes.marginForm}
            label="Vendor Name"
            variant={"outlined"}
            name="name"
            defaultValue={formState.name}
            onChange={handleChange}
            size={"small"}
            fullWidth
            inputRef={register}
            placeholder={"Masukan Nama Vendor"}
            error={errorForm.name ? true : false}
            helperText={errorForm.name && errorForm.name[0]}
          />

          <TextField
            className={classes.marginForm}
            label="Vendor Address"
            variant={"outlined"}
            name="address"
            defaultValue={formState.address}
            onChange={handleChange}
            size={"small"}
            fullWidth
            inputRef={register}
            placeholder={"Masukan Alamat Vendor"}
            error={errorForm.address ? true : false}
            helperText={errorForm.address && errorForm.address[0]}
          />

          <TextField
            className={classes.marginForm}
            label="Vendor Phone"
            variant={"outlined"}
            name="phone"
            defaultValue={formState.phone}
            onChange={handleChange}
            size={"small"}
            fullWidth
            inputRef={register}
            // type={"number"}
            placeholder={"Masukan no.ponsel Vendor"}
            error={errors.phone ? true : false}
            helperText={errors.phone && errors.phone.message}
          />

          <TextField
            className={classes.marginForm}
            label="PIC Vendor"
            variant={"outlined"}
            name="contact"
            defaultValue={formState.contact}
            onChange={handleChange}
            size={"small"}
            fullWidth
            inputRef={register}
            placeholder={"Masukan Kontak Vendor"}
            error={errorForm.contact ? true : false}
            helperText={errorForm.contact && errorForm.contact[0]}
          />

          <Grid container justify={"flex-end"} spacing={2}>
            <Grid item>
              <Button variant={"contained"} size={"medium"} className={classes.btnWarning} onClick={() => closedModalDialog()}>
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button variant={"contained"} size={"medium"} className={classes.btnSuccess} type={"submit"}>
                Update
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </form>
    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    loading: state.vendor.loading,
    errorForm: state.vendor.errors
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUpdateVendor: (id, storeData) => dispatch(actions.updateVendor(id,storeData)),
    onClearErrorVendor: () => dispatch(actions.clearErrorVendor())
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(Update);