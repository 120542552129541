import {makeStyles} from "@material-ui/core";

const Main = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  row: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  tagMenu: {
    fontWeight: "bold"
  },
  btnWarning: {
    color: theme.palette.white,
    backgroundColor: theme.palette.warning.main,
    textTransform: "none",
    fontWeight: "bold",
    borderRadius: 5,
    '&:hover': {
      backgroundColor: theme.palette.warning.light
    }
  },
  btnSuccess: {
    color: theme.palette.white,
    backgroundColor: theme.palette.success.main,
    textTransform: "none",
    fontWeight: "bold",
    borderRadius: 5,
    '&:hover': {
      backgroundColor: theme.palette.success.light
    }
  },
  btnError: {
    color: theme.palette.white,
    backgroundColor: theme.palette.error.main,
    textTransform: "none",
    fontWeight: "bold",
    borderRadius: 5,
    '&:hover': {
      backgroundColor: theme.palette.error.light
    }
  },
  textPrimary: {
    color: theme.palette.primary.main
  },
  textWarning: {
    color: theme.palette.warning.main
  },
  textError: {
    color: theme.palette.error.main
  },
  marginForm: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  textCapitalize: {
    textTransform: "capitalize"
  }
}));

export default Main;