import React, {Fragment, useState} from "react";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import {Button, Grid} from "@material-ui/core";
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers';
import * as actions from '../../../../../store/actions';
import {connect} from "react-redux";
import {Loading} from "../../../../../components/UI";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import NumberFormat from "react-number-format";

const schema = yup.object().shape({
});

const Form = props => {
  const {
    classes,
    product,
    history,
    onUpdateProduct,
    loading,
    errors,
  } = props;

  const [formState, setFormState] = useState({
    name: product.name,
    weight: product.weight,
    unit: product.unit,
  });

  const handleChange = (event) => {
    const target = event.target.name
    event.persist()

    if(event.target.type === 'checkbox'){
      setFormState((formState) => ({
        ...formState,
        [target]: event.target.checked
      }))
    }else{
      setFormState((formState) => ({
        ...formState,
        [target]: event.target.value
      }))
    }
  }

  const onSubmit = data => {
    // onStoreProduct(data,history);
    let numberWithoutPoint = ((data.weight).replace(/[.]/g, ''));
    let numbersComaToPoint = ((numberWithoutPoint).replace(/[,]/g, '.'));
    data.weight = numbersComaToPoint;
    onUpdateProduct(product.id, data, history);
    // console.log(data);
  }

  const { register, handleSubmit } = useForm({
    resolver: yupResolver(schema)
  })

  return (loading ? <Loading/> :
    <Fragment>
      <div className={classes.row}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Paper className={classes.root}>
            <TextField
              className={classes.marginForm}
              label="Product Name"
              variant={"outlined"}
              name="name"
              defaultValue={formState.name}
              onChange={handleChange}
              size={"small"}
              fullWidth
              inputRef={register}
              placeholder={"Masukan Nama Product"}
              error={errors.name ? true : false}
              helperText={errors.name && errors.name[0]}
            />

            <FormControl
              error={errors.weight ? true : false}
              variant="outlined" className={classes.formControl} fullWidth>
              <NumberFormat
                style={{ padding: '10.5px 14px', width: '100%' }}
                allowLeadingZeros={false}
                thousandSeparator="."
                decimalSeparator={','}
                // prefix={'Rp.'}
                name="weight"
                getInputRef={register}
                isNumericString={true}
                defaultValue={formState.weight}
                onChange={handleChange}
                placeholder="Masukan Berat Produk"
              />
              <FormHelperText>{errors.weight && errors.weight[0]}</FormHelperText>
            </FormControl>

            <FormControl
              error={errors.unit ? true : false}
              variant="outlined" fullWidth className={classes.marginForm}
              size={"small"}
            >
              <InputLabel htmlFor="outlined-age-native-simple">Unit</InputLabel>
              <Fragment>
                <Select
                  fullWidth
                  native
                  defaultValue={formState.unit}
                  onChange={handleChange}
                  label="Unit"
                  inputProps={{
                    name: 'unit',
                    id: 'outlined-age-native-simple'
                  }}
                  name="unit"
                  inputRef={register}
                  // onChange={handleChange}
                >
                  <option aria-label="None" value="" />
                  <option value="Gram">Gram</option>
                </Select>
                <FormHelperText>{errors.unit && errors.unit[0]}</FormHelperText>
              </Fragment>
            </FormControl>

            <Grid container justify={"flex-end"}>
              <Grid item>
                <Button variant={"contained"} size={"medium"} className={classes.btnWarning} type={"submit"}>
                  Update
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </form>
      </div>
    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    loading: state.product.loading,
    errors: state.product.errors
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUpdateProduct: (id, storeData, history) => dispatch(actions.updateProduct(id, storeData, history)),
  };
};

export default connect(mapStateToProps,mapDispatchToProps)(Form);