import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../../shared/utility'

const initialState = {
  token: null,
  user: {},
  userData: {},
  uploadUrl: null,
  changing: 0,
  errors: {},
  loading: false,
  // authRedirectPath: '/dashboard'
}

const authStart = (state, action) => {
  return updateObject(state, { loading: true })
}

const authSuccess = (state, action) => {
  return updateObject(state, {
    token: action.tokenId,
    user: action.userId,
    userData: action.userData,
    errors: {},
    loading: false
  })
}

const authFail = (state, action) => {
  return updateObject(state, { errors: action.errors, loading: false })
}

const resetPasswordStart = (state, action) => {
  return updateObject(state, { loading: true })
}

const resetPasswordSuccess = (state, action) => {
  return updateObject(state, {
    errors: {},
    loading: false
  })
}

const resetPasswordFail = (state, action) => {
  return updateObject(state, { errors: action.errors, loading: false })
}

const changePasswordStart = (state, action) => {
  return updateObject(state, { loading: true })
}

const changePasswordSuccess = (state, action) => {
  return updateObject(state, {
    errors: {},
    loading: false
  })
}

const changePasswordFail = (state, action) => {
  return updateObject(state, { errors: action.errors, loading: false })
}

const authCheck = (state, action) => {
  return updateObject(state, {
    user: action.user,
    token: action.token
  })
}

const clearErrorAuth = (state, action) => {
  return updateObject(state, { errors: {} })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START: return authStart(state, action)
    case actionTypes.AUTH_SUCCESS: return authSuccess(state, action)
    case actionTypes.AUTH_FAIL: return authFail(state, action)
    case actionTypes.RESET_PASSWORD_START: return resetPasswordStart(state, action)
    case actionTypes.RESET_PASSWORD_SUCCESS: return resetPasswordSuccess(state, action)
    case actionTypes.RESET_PASSWORD_FAIL: return resetPasswordFail(state, action)
    case actionTypes.CHANGE_PASSWORD_START: return changePasswordStart(state, action)
    case actionTypes.CHANGE_PASSWORD_SUCCESS: return changePasswordSuccess(state, action)
    case actionTypes.CHANGE_PASSWORD_FAIL: return changePasswordFail(state, action)
    case actionTypes.AUTH_CHECK: return authCheck(state, action)
    case actionTypes.CLEAR_ERROR_AUTH: return clearErrorAuth(state, action)

    default: return state
  }
}

export default reducer
