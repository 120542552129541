import React, { Fragment, useEffect } from "react";
import { Grid } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import withStyles from "@material-ui/core/styles/withStyles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Alert from "../../../../../../components/layout/Alert";
import { CheckCircleOutline, ErrorOutline, Warning } from "@material-ui/icons";
import { connect } from "react-redux";
import { isEmpty } from "../../../../../../shared/utility";
import palette from '../../../../../../theme/palette';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const DialogSuccess = props => {
  const {
    classes,
    contentDialog
  } = props;

  console.log(contentDialog);
  useEffect(() => {

  }, [contentDialog])
  return (isEmpty(contentDialog) ? '' :
    <Fragment>
      <Grid container spacing={2}>
        <Grid item xl={12} md={12} sm={12} xs={12}>
          <div style={{
            background:
              contentDialog.status === "success" ? palette.success.main :
                contentDialog.status === "warning" ? palette.warning.main :
                  contentDialog.status === "failed" ? palette.error.main :
                    palette.error.main
            ,
            color: 'white',
            borderColor: 'black',
            borderRadius: '8px',
            padding: '5px',
            display: 'flex'
          }}>
            <div style={{ marginRight: '5px' }}>
              {
                contentDialog.status === "success" ? <CheckCircleOutline /> :
                  contentDialog.status === "warning" ? <Warning /> :
                    contentDialog.status === "failed" ? <ErrorOutline /> :
                      <ErrorOutline />
              }

            </div>
            <div>{contentDialog.message}</div>
          </div>
          {console.log(contentDialog)}
          <Alert
            variant="outlined"
            severity="error"
          // severity={
          // contentDialog.status === "success" ? "success" :
          //   contentDialog.status === "warning" ? "warning" :
          //     contentDialog.status === "failed" ? "error" :
          //       "error"
          // }
          >

          </Alert>{/*<Alert iconMapping={{ success: <CheckCircleOutline fontSize="inherit" /> }}>*/}

        </Grid>
      </Grid>
      <Grid container spacing={2} className={classes.marginTop}>
        <Grid item xl={4} md={4} sm={12} xs={12}>
          <div className={classes.marginBottom}>Jumlah Data</div>
          <div className={classes.fontWeight}>{contentDialog.total}</div>
        </Grid>
        <Grid item xl={4} md={4} sm={12} xs={12}>
          <div className={classes.marginBottom}>Record Data Berhasil</div>
          <div className={classes.fontWeight}> {contentDialog.success}</div>
        </Grid>
        <Grid item xl={4} md={4} sm={12} xs={12}>
          <div className={classes.marginBottom}>Record Data Gagal</div>
          <div className={classes.fontWeight}>{contentDialog.failed}</div>
        </Grid>
      </Grid>
      <div className={classes.marginTop}>
        List Gold (Total = {contentDialog.total})
      </div>
      {/*<Paper className={classes.panelPadding}>*/}
      {/*  contentDialog*/}
      {/*</Paper>*/}
      <TableContainer component={Paper} className={classes.marginTop25}>
        <Table className={classes.table} aria-label="customized table">
          <TableBody>
            {contentDialog.data.map((row) => (
              <StyledTableRow key={row.name}>
                <StyledTableCell component="th" scope="row">
                  {row.name}
                </StyledTableCell>
                <StyledTableCell align="left">{row.code}</StyledTableCell>
                <StyledTableCell align="left">{row.qr_code}</StyledTableCell>
                <StyledTableCell align="left">
                  <div className={row.status === 'Gagal' ? classes.textError : classes.textSuccess}>
                    {row.status}
                  </div>
                </StyledTableCell>
                {/*<StyledTableCell align="right">{row.protein}</StyledTableCell>*/}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    contentDialog: state.scan.contentDialog
  };
};

export default connect(mapStateToProps)(DialogSuccess);