import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';


import {
  FormProductCheck as FormProductCheckView,
  ProductCheckDetail as ProductCheckDetailView,
  ProductCheckFailed as ProductCheckFailedView,
  CheckProductGold as CheckProductGoldView
} from './views';

const Routes = (props) => {

  // const user = JSON.parse(sessionStorage.getItem('user'));

  return (
    <Switch>
      <Redirect
        exact
        from="/"
        to="/form-product-check"
      />

      <Route
        component={ProductCheckDetailView}
        exact
        // layout={Login}
        path="/product-check-detail/:id"
      />

      <Route
        component={FormProductCheckView}
        exact
        // layout={Login}
        path="/form-product-check"
      />

      <Route
        component={ProductCheckFailedView}
        exact
        // layout={Login}
        path="/product-check-fail"
      />

      <Route
        component={CheckProductGoldView}
        exact
        // layout={Login}
        path="/c/:c"
      />

      <Route
        component={ProductCheckFailedView}
        exact
        // layout={Login}
        path="/product-check-fail"
      />

      <Redirect to="/"/>
    </Switch>
  );
};

export default (Routes);
