import React,{Fragment} from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

const Delete = props => {
  const {
    classes,
    data,
    closedModalDialog,
    deleted
  } = props;
  return (
    <Fragment>
      <Box display={"flex"} justifyContent={"center"} className={classes.boxMarginRow}>
        <Box>
          <Typography variant={"h5"} align={"center"}>
            Yakin ingin menghapus data Product "{data.name} {data.weight} {data.unit}" ?
          </Typography>
        </Box>
      </Box>
      <br/>
      <Box display={"flex"} justifyContent="flex-end">
        <Box m={1}>
          <Button
            variant={"outlined"}
            size={"small"}
            className={classes.btnWarning}
            onClick={closedModalDialog}
          >
            Batal
          </Button>
        </Box>
        <Box m={1}>
          <Button
            variant={"contained"}
            size={"small"}
            className={classes.btnError}
            onClick={() => deleted(data.id)}
            // onClick={() => sent(data)}
          >
            Hapus
          </Button>
        </Box>
      </Box>
    </Fragment>
  )
}

export default Delete;