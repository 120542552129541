import React,{Fragment} from "react";
import {connect} from "react-redux";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Paper from "@material-ui/core/Paper";
import {isEmpty} from "../../../../shared/utility";
import TableData from '../List/TableData/TableData';
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import {TablePaginationActions} from "../../../../components/UI";
import TableBody from "@material-ui/core/TableBody";
import {useHistory} from 'react-router-dom';

const columns = [
  { id: 'no', label: 'No', minWidth: 20 },
  { id: 'nama_user', label: 'Nama User', minWidth: 100 },
  { id: 'email', label: 'Email', minWidth: 100 },
  { id: 'akses', label: 'Akses', minWidth: 100 },
  { id: 'role', label: 'Role', minWidth: 100 },
  { id: 'action', label: 'Action', minWidth: 100 },
];

const List = props => {
  const {
    classes,
    users,
    dialogDelete,
    propsHandleChangePage
  } = props;
  const history = useHistory();

  const handleChangePage = (event, newPage) => {
    propsHandleChangePage(newPage)
  }

  let tableBody = '';
  if(!isEmpty(users)){
    const page = users.current_page - 1;
    const rowsPerPage = 10;
    const countRows = users.total_item
    // console.log(page, rowsPerPage, countRows, emptyRows);
    //
    tableBody = (
      <Fragment>
        <TableBody>
          {users.data.map((user, index) => (
            <TableData
              user={user}
              key={user.id}
              index={index}
              classes={classes}
              deleted={(data) => deleted(data)}
              edited={(data) => history.push(`management-user/${data.id}`)}
            />
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[rowsPerPage]}
              colSpan={10}
              count={countRows}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Fragment>
    )
  }

  const deleted = (data) => {
    dialogDelete(data);
  }

  return (
    <Fragment>
      <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table" style={{ minWidth: "340px" }}>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {tableBody}
        </Table>
      </TableContainer>
      </Paper>
    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    users: state.user.users
  };
};

export default connect(mapStateToProps)(List);