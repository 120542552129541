import React, {Fragment, useEffect} from "react";

import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Grid, withStyles } from '@material-ui/core';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import AppBar from "@material-ui/core/AppBar";
import {FormChangeName, FormChangePassword} from "./components";

import * as actions from '../../store/actions';
import {connect} from "react-redux";
import {Loading} from "../../components/UI";
import {isEmpty} from "../../shared/utility";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} style={{padding: '0px'}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    // flexGrow: 1,
    // width: '100%',
    // backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4),
    // margin: theme.spacing(2)
  },
  card: {
    width: '100%'
  },
  appBar: {
    backgroundColor: '#FFFFFF',
    boxShadow: 'none',
    // flexDirection: 'unset',
  },
  text: {
    fontFamily: 'Nunito'
  },
  pageName: {
    fontSize: '20',
    fontWeight: 'bold',
    marginBottom: '20px'
  },
  cardContent: {
    backgroundColor: '#F7F9FC'
  },
  btnPrimary: {
    backgroundColor: '#0277BD',
    color: '#FFFFFF',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#035D93'
    }
  },
  colorPrimary: {
    color: theme.palette.primary.main,
  },
  searchRoot: {
    margin: '20px 0px',
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 'auto'
    // marginTop: theme.spacing(2)
  },
  divider: {
    height: 28,
    margin: 4
  },
  iconButton: {
    // padding: 10,
    backgroundColor: '#0277BD',
    '&:hover': {
      backgroundColor: '#0277BD'
    },
    color: '#FFFFFF',
    borderRadius: 0
  },
  searchSelectRoot: {
    padding: '2px 10px',
    display: 'flex',
    alignItems: 'center',
    width: 'auto'
    // marginTop: theme.spacing(2)
  },
  labelFont: {
    fontSize: '12px'
  },
  tabs: {
    flex: 'none'
  },
  btnWarning: {
    color: theme.palette.white,
    backgroundColor: theme.palette.warning.main,
    textTransform: "none",
    fontWeight: "bold",
    borderRadius: 5,
    '&:hover': {
      backgroundColor: theme.palette.warning.light
    }
  }
}));

const TabsCustom = withStyles((theme) => ({
  scroller: {
    flex: 'none'
  }
}))((props) => <Tabs disableRipple {...props} />);

const TabCustom = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    margin: 'auto'
  },
  textColorPrimary: {
    '& > selected': {
      color: theme.palette.warning.main
    }
  },
  wrapper: {
    fontWeight: "bold"
  },
  selected: {
    color: theme.palette.warning.main+'!important'
  },
}))((props) => <Tab disableRipple {...props} />);

const Profile = props => {
  const {
    onGetProfile,
    user,
    loading,
    changing
  } = props;
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    onGetProfile()
  },[onGetProfile,changing])

  return (loading ? <Loading/> :
    <Fragment>
      <div className={classes.root}>
        <div className={classes.row}>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
          >
            <Card className={classes.card}>
              <CardContent>
                <AppBar position="static" className={classes.appBar}>
                  <TabsCustom
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                    className={classes.tabs}
                  >
                    <TabCustom label="Ubah Nama" {...a11yProps(0)} />
                    <TabCustom label="Ubah Password" {...a11yProps(1)} />
                    {/*<Tab label="Syirkah Opsi 3" {...a11yProps(2)} />*/}
                  </TabsCustom>
                </AppBar>
                {/*<div className={classes.row}>*/}
                {/*  */}
                {/*</div>*/}
                <hr/>
                <div style={{margin: '20px auto 0px'}}>
                  <TabPanel value={value} index={0}>
                    {!isEmpty(user) ? <FormChangeName user={user} classes={classes}/> : loading}

                    {/*<ListSyirkah />*/}
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    {!isEmpty(user) ? <FormChangePassword user={user} classes={classes}/> : loading}
                    {/*<ListSyirkahUnitBisnis/>*/}
                  </TabPanel>
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    loading: state.profile.loading,
    user: state.profile.user,
    changing: state.profile.changing,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onGetProfile: () => dispatch(actions.getProfile())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);