import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../../shared/utility'

const initialState = {
  token: null,
  users: {},
  user: {},
  changing: 0,
  errors: {},
  loading: false,
  // authRedirectPath: '/dashboard'
}

const fetchUsersStart = (state, action) => {
  return updateObject(state, { loading: true })
}

const fetchUsersSuccess = (state, action) => {
  return updateObject(state, {
    users: action.users,
    errors: {},
    loading: false
  })
}

const fetchUsersFail = (state, action) => {
  return updateObject(state, { errors: action.errors, loading: false })
}

const storeUserStart = (state, action) => {
  return updateObject(state, { loading: true })
}

const storeUserSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    changing: state.changing+1
  })
}

const storeUserFail = (state, action) => {
  return updateObject(state, { errors: action.errors, loading: false })
}

const getUserStart = (state, action) => {
  return updateObject(state, { loading: true })
}

const getUserSuccess = (state, action) => {
  return updateObject(state, {
    user: action.user,
    loading: false
  })
}

const getUserFail = (state, action) => {
  return updateObject(state, { errors: action.errors, loading: false })
}

const updateUserStart = (state, action) => {
  return updateObject(state, { loading: true })
}

const updateUserSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    changing: state.changing+1
  })
}

const updateUserFail = (state, action) => {
  return updateObject(state, { errors: action.errors, loading: false })
}

const deleteUsersStart = (state, action) => {
  return updateObject(state, { loading: true })
}

const deleteUsersSuccess = (state, action) => {
  return updateObject(state, {
    changing: state.changing+1,
    loading: false
  })
}

const deleteUsersFail = (state, action) => {
  return updateObject(state, { errors: action.errors, loading: false })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_USERS_START: return fetchUsersStart(state, action)
    case actionTypes.FETCH_USERS_SUCCESS: return fetchUsersSuccess(state, action)
    case actionTypes.FETCH_USERS_FAIL: return fetchUsersFail(state, action)
    case actionTypes.STORE_USER_START: return storeUserStart(state, action)
    case actionTypes.STORE_USER_SUCCESS: return storeUserSuccess(state, action)
    case actionTypes.STORE_USER_FAIL: return storeUserFail(state, action)
    case actionTypes.GET_USER_START: return getUserStart(state, action)
    case actionTypes.GET_USER_SUCCESS: return getUserSuccess(state, action)
    case actionTypes.GET_USER_FAIL: return getUserFail(state, action)
    case actionTypes.UPDATE_USER_START: return updateUserStart(state, action)
    case actionTypes.UPDATE_USER_SUCCESS: return updateUserSuccess(state, action)
    case actionTypes.UPDATE_USER_FAIL: return updateUserFail(state, action)
    case actionTypes.DELETE_USER_START: return deleteUsersStart(state, action)
    case actionTypes.DELETE_USER_SUCCESS: return deleteUsersSuccess(state, action)
    case actionTypes.DELETE_USER_FAIL: return deleteUsersFail(state, action)
    // case actionTypes.AUTH_LOGOUT: return fetchUsersLogout(state, action)

    default: return state
  }
}

export default reducer