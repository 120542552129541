import React, {Fragment, useEffect, useState} from "react";
import {Main} from '../../style';
import {Breadcrumbs, Button, Grid, Link, Typography} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers';
import * as actions from '../../../../store/actions';
import {connect} from "react-redux";
import {useHistory} from 'react-router-dom';
import {Loading} from "../../../../components/UI";
import {CustomRouterLink} from "../../../../shared/utility";
import FormControl from "@material-ui/core/FormControl";
import NumberFormat from "react-number-format";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
// import FormHelperText from "@material-ui/core/FormHelperText";

const schema = yup.object().shape({
  // weight: yup.string().required(),
});

const Create = props => {
  const classes = Main();
  const history = useHistory();
  const {
    onStoreProduct,
    loading,
    errorForm,
    onClearErrorProduct
  } = props;

  useEffect(() => {
    onClearErrorProduct()
  },[onClearErrorProduct])

  const [formState, setFormState] = useState({
    name: '',
    weight: '',
    unit: '',
  });

  const handleChange = (event) => {
    const target = event.target.name
    event.persist()

    if(event.target.type === 'checkbox'){
      setFormState((formState) => ({
        ...formState,
        [target]: event.target.checked
      }))
    }else{
      setFormState((formState) => ({
        ...formState,
        [target]: event.target.value
      }))
    }
  }

  const { register, handleSubmit } = useForm({
    resolver: yupResolver(schema)
  })

  const onSubmit = data => {
    let numberWithoutPoint = ((data.weight).replace(/[.]/g, ''));
    let numbersComaToPoint = ((numberWithoutPoint).replace(/[,]/g, '.'));
    data.weight = numbersComaToPoint;
    // console.log(data);
    onStoreProduct(data,history);
  }

  return (loading ? <Loading/> :
    <Fragment>
      <div className={classes.root}>
        <div className={classes.row}>
          <div>
            <div className={classes.tagMenu}>
              Management Product
            </div>
            <div>
              <Breadcrumbs aria-label="breadcrumb">
                <Link color="inherit" component={CustomRouterLink} to="/dashboard">
                  Dashboard
                </Link>
                <Link color="inherit" component={CustomRouterLink} to="/product">
                  Management Product
                </Link>
                <Typography color="textPrimary">Create Product</Typography>
              </Breadcrumbs>
            </div>
          </div>
        </div>
        <div className={classes.row}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Paper className={classes.root}>
              <TextField
                className={classes.marginForm}
                label="Product Name"
                variant={"outlined"}
                name="name"
                defaultValue={formState.name}
                onChange={handleChange}
                size={"small"}
                fullWidth
                inputRef={register}
                placeholder={"Masukan Nama Product"}
                error={errorForm.name ? true : false}
                helperText={errorForm.name && errorForm.name[0]}
              />

              <FormControl
                error={errorForm.weight ? true : false}
                variant="outlined" className={classes.formControl} fullWidth>
                <NumberFormat
                  style={{ padding: '10.5px 14px', width: '100%' }}
                  allowLeadingZeros={false}
                  thousandSeparator="."
                  decimalSeparator={','}
                  // prefix={'Rp.'}
                  name="weight"
                  getInputRef={register}
                  isNumericString={true}
                  defaultValue={formState.weight}
                  onChange={handleChange}
                  placeholder="Masukan Berat Produk"
                />
                <FormHelperText>{errorForm.weight && errorForm.weight[0]}</FormHelperText>
              </FormControl>

              <FormControl
                error={errorForm.unit ? true : false}
                variant="outlined" fullWidth className={classes.marginForm}
                size={"small"}
              >
                <InputLabel htmlFor="outlined-age-native-simple">Unit</InputLabel>
                <Fragment>
                  <Select
                    fullWidth
                    native
                    defaultValue={formState.unit}
                    onChange={handleChange}
                    label="Unit"
                    inputProps={{
                      name: 'unit',
                      id: 'outlined-age-native-simple'
                    }}
                    name="unit"
                    inputRef={register}
                    // onChange={handleChange}
                  >
                    <option aria-label="None" value="" />
                    <option value="Gram">Gram</option>
                  </Select>
                  <FormHelperText>{errorForm.unit && errorForm.unit[0]}</FormHelperText>
                </Fragment>
              </FormControl>

              <Grid container justify={"flex-end"}>
                <Grid item>
                  <Button variant={"contained"} size={"medium"} className={classes.btnSuccess} type={"submit"}>
                    Simpan
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    loading: state.product.loading,
    errorForm: state.product.errors
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onStoreProduct: (storeData, history) => dispatch(actions.storeProduct(storeData,history)),
    onClearErrorProduct: () => dispatch(actions.clearErrorProduct())
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(Create);