import * as actions from './actionTypes'
import axios from '../../axios-orders'
import { setAlert } from './alert'

export const fetchScanStart = () => {
  return {
    type: actions.FETCH_SCANS_START
  }
}

export const fetchScanSuccess = (scans) => {
  return {
    type: actions.FETCH_SCANS_SUCCESS,
    scans: scans
  }
}

export const fetchScanFail = (errors) => {
  return {
    type: actions.FETCH_SCANS_FAIL,
    errors: errors
  }
}

export const fetchScan = (page, formSearch) => {
  let params = `&status=${formSearch.status}&start=${formSearch.start_date}&end=${formSearch.end_date}&keyword=${formSearch.search}&vendor_id=${formSearch.vendor_id}&product_id=${formSearch.product_id}`;
  return dispatch => {
    dispatch(fetchScanStart())

    axios.get(`/api/admin/scan?page=${page}${params}`, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`
      }
    })
      .then(response => {
        dispatch(fetchScanSuccess(response.data))
      })
      .catch(err => {
        dispatch(fetchScanFail(err.response.data.message))
        dispatch(setAlert(err.response.data.message, "error"))
      })
  }
}

export const storeScanStart = () => {
  return {
    type: actions.STORE_SCAN_START
  }
}

export const storeScanSuccess = (contentDialog) => {
  return {
    type: actions.STORE_SCAN_SUCCESS,
    contentDialog: contentDialog
  }
}

export const storeScanFail = (errors) => {
  return {
    type: actions.STORE_SCAN_FAIL,
    errors: errors
  }
}

export const storeScan = (storeData, vendorId, history) => {
  return dispatch => {
    dispatch(storeScanStart())
    const bodyFormData = new FormData()
    bodyFormData.set('date', storeData.date)
    bodyFormData.set('file', storeData.file)
    bodyFormData.set('seq', storeData.seq)
    bodyFormData.set('product_id', storeData.product_id)
    bodyFormData.set('vendor_id', storeData.vendor)

    axios({
      method: 'post',
      url: `api/admin/scan/vendor/${vendorId}/import`,
      data: bodyFormData,
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
      }
    })
      .then(response => {
        // dispatch(setAlert(response.data.message, 'success'))
        dispatch(storeScanSuccess(response.data))
        // history.push(`/dashboard`);
      })
      .catch(error => {
        dispatch(storeScanFail(error.response.data.errors ? error.response.data.errors : {}))
        dispatch(setAlert(error.response.data.message, 'error'))
      })
  }
}

export const updateScanStart = () => {
  return {
    type: actions.UPDATE_SCAN_START
  }
}

export const updateScanSuccess = () => {
  return {
    type: actions.UPDATE_SCAN_SUCCESS
  }
}

export const updateScanFail = (errors) => {
  return {
    type: actions.UPDATE_SCAN_FAIL,
    errors: errors
  }
}

export const updateScan = (id, storeData) => {
  return dispatch => {
    dispatch(updateScanStart())
    axios.put(`api/admin/scan/${id}`, storeData, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`
      }
    })
      .then(response => {
        dispatch(setAlert(response.data.message, 'success'))
        dispatch(updateScanSuccess(response.data))
        // history.push(`/vendor`);
      })
      .catch(error => {
        dispatch(updateScanFail(error.response.data.errors ? error.response.data.errors : {}))
        dispatch(setAlert(error.response.data.message, 'error'))
      })
  }
}

export const closedDialog = () => {
  return {
    type: actions.CLOSED_DIALOG
  }
}

export const clearError = () => {
  return {
    type: actions.CLEAR_ERROR
  }
}

export const productCheckStart = () => {
  return {
    type: actions.PRODUCT_CHECK_START
  }
}

export const productCheckSuccess = () => {
  return {
    type: actions.PRODUCT_CHECK_SUCCESS
  }
}

export const productCheckFail = (errors) => {
  return {
    type: actions.PRODUCT_CHECK_FAIL,
    errors: errors
  }
}

export const productCheck = (code, history) => {
  // let params = `&status=${formSearch.status}&start=${formSearch.start_date}&end=${formSearch.end_date}&keyword=${formSearch.search}&vendor_id=${formSearch.vendor_id}&product_id=${formSearch.product_id}`;
  return dispatch => {
    dispatch(productCheckStart())

    axios.get(`/api/scan/validity/${code}`, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
      .then(response => {
        (response.data.status === 'available' ? history.push(`/product-check-detail/${response.data.data.id}`) : history.push('/product-check-fail') )
        dispatch(productCheckSuccess())
        // (
        //   response.data.status === 'available' ?
        //     // history.push(`product-check-detail/${response.data.data.id}`)
        //     // console.log('test')
        //     :
        //     history.push('product-check-fail')
        // )
      })
      .catch(err => {
        console.log(err)
        dispatch(productCheckFail())
        dispatch(setAlert('errors', "error"))
      })
  }
}

export const productDetailStart = () => {
  return {
    type: actions.GET_PRODUCT_DETAIL_START
  }
}

export const productDetailSuccess = (scan) => {
  return {
    type: actions.GET_PRODUCT_DETAIL_SUCCESS,
    scan: scan
  }
}

export const productDetailFail = (errors) => {
  return {
    type: actions.GET_PRODUCT_DETAIL_FAIL,
    errors: errors
  }
}

export const productDetail = (id) => {
  // let params = `&status=${formSearch.status}&start=${formSearch.start_date}&end=${formSearch.end_date}&keyword=${formSearch.search}&vendor_id=${formSearch.vendor_id}&product_id=${formSearch.product_id}`;
  return dispatch => {
    dispatch(productDetailStart())

    axios.get(`/api/scan/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    })
      .then(response => {
        // history.push('')
        dispatch(productDetailSuccess(response.data))
      })
      .catch(err => {
        dispatch(productDetailFail(err.response.data.message))
        dispatch(setAlert(err.response.data.message, "error"))
      })
  }
}