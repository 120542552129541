import React,{Fragment} from "react";
import main from "../../style/main";
import {Button, Grid, Paper, Typography} from "@material-ui/core";
import {CustomRouterLink} from "../../../../shared/utility";
import CancelIcon from "@material-ui/icons/Cancel";

const ProductCheckFailed = props => {
  const classes = main();

  return (
    <Fragment>
      <Grid container>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Grid container>
            <Grid item lg={3} md={3} sm={12} xs={12} />
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Paper className={classes.paper}>
                <Grid container justify={"center"}>
                  <Grid item>
                    <img src={require('../../../../assets/images/logo/Logo-EOA-Scanner.png').default} alt="club" className={classes.logoClub} onClick={() => window.open('https://www.eoagold.id/')}/>
                  </Grid>

                </Grid>
                <div>
                  <CancelIcon style={{marginTop: "10px", fontSize:"50px", color: 'red'}}/>
                </div>
                <div>
                  <Typography variant={"h4"} style={{marginTop: "10px" }}>
                    MAAF KODE TIDAK DITEMUKAN
                  </Typography>
                  <Typography variant={"h5"} style={{marginTop: "10px" }}>
                    Sangat Mungkin Kode ini
                  </Typography>
                  <Typography variant={"h3"} style={{fontWeight: "bold", marginTop: "10px", color:"red" }}>
                    PALSU
                  </Typography>
                </div>
                <Grid container>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      component={CustomRouterLink}
                      to={'form-product-check'}
                      className={classes.submit}
                    >
                      Cek Ulang
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default ProductCheckFailed;