import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../../shared/utility'

const initialState = {
  products: {},
  product: {},
  allProduct: {},
  changing: 0,
  errors: {},
  loading: false,
  // authRedirectPath: '/dashboard'
}

const fetchProductsStart = (state, action) => {
  return updateObject(state, { loading: true })
}

const fetchProductsSuccess = (state, action) => {
  return updateObject(state, {
    products: action.products,
    loading: false
  })
}

const fetchProductsFail = (state, action) => {
  return updateObject(state, { errors: action.errors, loading: false })
}

const getProductStart = (state, action) => {
  return updateObject(state, { loading: true })
}

const getProductSuccess = (state, action) => {
  return updateObject(state, {
    product: action.product,
    loading: false
  })
}

const getProductFail = (state, action) => {
  return updateObject(state, { errors: action.errors, loading: false })
}

const getAllProductStart = (state, action) => {
  return updateObject(state, { loading: true })
}

const getAllProductSuccess = (state, action) => {
  return updateObject(state, {
    allProduct: action.allProduct,
    loading: false
  })
}

const getAllProductFail = (state, action) => {
  return updateObject(state, { errors: action.errors, loading: false })
}

const storeProductStart = (state, action) => {
  return updateObject(state, { loading: true })
}

const storeProductSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    changing: state.changing+1
  })
}

const storeProductFail = (state, action) => {
  return updateObject(state, { errors: action.errors, loading: false })
}

const updateProductStart = (state, action) => {
  return updateObject(state, { loading: true })
}

const updateProductSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    changing: state.changing+1
  })
}

const updateProductFail = (state, action) => {
  return updateObject(state, { errors: action.errors, loading: false })
}

const deleteProductStart = (state, action) => {
  return updateObject(state, { loading: true })
}

const deleteProductSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    changing: state.changing+1
  })
}

const deleteProductFail = (state, action) => {
  return updateObject(state, { errors: action.errors, loading: false })
}

const clearErrorProduct = (state, action) => {
  return updateObject(state, { errors: {} })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_PRODUCTS_START: return fetchProductsStart(state, action)
    case actionTypes.FETCH_PRODUCTS_SUCCESS: return fetchProductsSuccess(state, action)
    case actionTypes.FETCH_PRODUCTS_FAIL: return fetchProductsFail(state, action)
    case actionTypes.GET_PRODUCT_START: return getProductStart(state, action)
    case actionTypes.GET_PRODUCT_SUCCESS: return getProductSuccess(state, action)
    case actionTypes.GET_PRODUCT_FAIL: return getProductFail(state, action)
    case actionTypes.GET_ALL_PRODUCT_START: return getAllProductStart(state, action)
    case actionTypes.GET_ALL_PRODUCT_SUCCESS: return getAllProductSuccess(state, action)
    case actionTypes.GET_ALL_PRODUCT_FAIL: return getAllProductFail(state, action)
    case actionTypes.STORE_PRODUCT_START: return storeProductStart(state, action)
    case actionTypes.STORE_PRODUCT_SUCCESS: return storeProductSuccess(state, action)
    case actionTypes.STORE_PRODUCT_FAIL: return storeProductFail(state, action)
    case actionTypes.UPDATE_PRODUCT_START: return updateProductStart(state, action)
    case actionTypes.UPDATE_PRODUCT_SUCCESS: return updateProductSuccess(state, action)
    case actionTypes.UPDATE_PRODUCT_FAIL: return updateProductFail(state, action)
    case actionTypes.DELETE_PRODUCT_START: return deleteProductStart(state, action)
    case actionTypes.DELETE_PRODUCT_SUCCESS: return deleteProductSuccess(state, action)
    case actionTypes.DELETE_PRODUCT_FAIL: return deleteProductFail(state, action)
    case actionTypes.CLEAR_ERROR_PRODUCT: return clearErrorProduct(state, action)

    default: return state
  }
}

export default reducer