import React, {Fragment, useEffect, useState} from "react";
import Paper from "@material-ui/core/Paper";
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers';
import TextField from "@material-ui/core/TextField";
import {Button, Grid} from "@material-ui/core";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import InputAdornment from "@material-ui/core/InputAdornment";
import {CalendarToday} from "@material-ui/icons";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import Dropzone from "../../../../components/UI/Dropzone/Dropzone";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import * as actions from "../../../../store/actions";
import {connect} from "react-redux";
import {useHistory} from "react-router-dom";
import {Loading, Modal} from "../../../../components/UI";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
// import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
// import {Delete} from "../../../ManagementUser/components";
import {DialogSuccess} from "./components";
import FormHelperText from "@material-ui/core/FormHelperText";
import {isEmpty} from "../../../../shared/utility";

const schema = yup.object().shape({
  vendor: yup.string().required("Vendor tidak boleh kosong"),
  // email: yup.string().required("Email tidak boleh kosong"),
});

const Form = props => {
  const {
    classes,
    onStoreScan,
    loading,
    errorsForm,
    statusDialog,
    onClosedDialog,
    onClearError,
    changing,
    onFetchVendors,
    vendors,
    loadingVendor,
    onGetAllProduct,
    allProduct
  } = props;
  const history = useHistory();

  const [formState, setFormState] = useState({
    date: moment(new Date()).format('YYYY-MM-DD'),
    seq: '',
    vendor: '',
    product_id: ''
  });

  useEffect(() => {
    setFormState(({
      date: moment(new Date()).format('YYYY-MM-DD'),
      seq: '',
      vendor: '',
      product_id: ''
    }))
    setDoc('');
    onClearError();
    onFetchVendors();
    onGetAllProduct();
    successDialog()// eslint-disable-next-line
  },[changing, onClearError, onFetchVendors,onGetAllProduct])

  const [ startDate, setStartDate ] = useState({
    startDate: new Date(),
  });

  const handleStartDate = (input) => {
    setStartDate(startDate => ({
      ...startDate,
      startDate: input
    }));
    setFormState(() => ({
      ...formState,
      date: moment(input).format('YYYY-MM-DD')
    }));
  };

  const [isOpen, setIsOpen] = useState(false);

  const handleChange = (event) => {
    const target = event.target.name
    event.persist()

    if(event.target.type === 'checkbox'){
      setFormState((formState) => ({
        ...formState,
        [target]: event.target.checked
      }))
    }else{
      setFormState((formState) => ({
        ...formState,
        [target]: event.target.value
      }))
    }
  }

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema)
  })

  const [doc, setDoc] = useState('');

  const onSubmit = data => {
    data.file = doc;
    onStoreScan(data, data.vendor,history)
  }

  const handleChangeBanner = event => {
    setDoc(event[0]);
    // if(event[0].size < 2000000){
    //   onUploadDocumentPO(event[0]);
    // }else{
    //   onAlert('File dokumen melebihi ukuran maksimal 2 mb', 'error')
    // }

  }

  const [Form, setForm] = useState('');
  const [modalState, setModalState] = useState({
    open: false,
    title: 'Status Import File',
    maxWidth: 'md',
  });
  const closedModalDialog = () => {
    setModalState({
      maxWidth: 'sm',
      title: '',
      open: false,
    });
    setForm('');
    onClosedDialog();
  };

  const successDialog = () => {
    setModalState({
      maxWidth: 'md',
      title: 'Status Import File',
      open: statusDialog,
    });
    setForm(<DialogSuccess classes={classes}/>);
  }

  return (loading || loadingVendor ? <Loading/> :
      <Fragment>
        <Modal
          maxWidth={modalState.maxWidth}
          open={modalState.open}
          title={modalState.title}
          onCloseModal={closedModalDialog}
          contentModal={Form}
        />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Paper className={classes.panelPadding}>
            <Grid container spacing={2}>
              <Grid item xl={8} md={8} sm={12} xs={12}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <TextField
                    variant={"outlined"}
                    onClick={() => setIsOpen(true)}
                    value={moment(startDate.startDate).format('dddd, DD MMMM YYYY')}
                    // name={'date'}
                    // inputRef={register}
                    fullWidth
                    className={classes.margin}
                    label="Date"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" disablePointerEvents>
                          <CalendarToday />
                        </InputAdornment>
                      ),
                    }}
                    // error={errors.date ? true : false}
                    // helperText={errors.date && errors.date[0]}
                  />
                  <TextField
                    value={moment(startDate.startDate).format('YYYY-MM-DD')}
                    name={'date'}
                    inputRef={register}
                    type={"hidden"}
                  />
                  <DatePicker
                    fullWidth
                    open={isOpen}
                    onOpen={() => setIsOpen(true)}
                    onClose={() => setIsOpen(false)}
                    disableFuture
                    // minDate={minDate}
                    variant="outlined"
                    name="start_date"
                    format="dd MMMM yyyy HH:mm"
                    value={startDate.startDate}
                    onChange={e => handleStartDate(e, 'endDate')}
                    TextFieldComponent={() => null}
                  />
                </MuiPickersUtilsProvider>

                <TextField
                  className={classes.marginForm}
                  label="Req. Seq."
                  variant={"outlined"}
                  name="seq"
                  defaultValue={formState.seq}
                  onChange={handleChange}
                  // size={"small"}
                  fullWidth
                  inputRef={register}
                  placeholder={"Masukan Nama Req. Seq."}
                  error={errorsForm.seq ? true : false}
                  helperText={errorsForm.seq && errorsForm.seq[0]}
                />

                <FormControl
                  error={errors.vendor ? true : false}
                  variant="outlined" fullWidth className={classes.marginForm}>
                  <InputLabel htmlFor="outlined-age-native-simple">Vendor</InputLabel>
                  {formState.vendor !== '' ? (
                    <Fragment>
                      <Select
                        fullWidth
                        native
                        defaultValue={formState.vendor}
                        onChange={handleChange}
                        label="Vendor"
                        inputProps={{
                          name: 'vendor',
                          id: 'outlined-age-native-simple'
                        }}
                        name="vendor"
                        inputRef={register}
                        // onChange={handleChange}
                      >
                        <option aria-label="None" value="" />
                        {!isEmpty(vendors) && (
                          vendors.data.map((vendor) => (
                            <option value={vendor.id}>{vendor.name}</option>
                          ))
                        )}
                      </Select>
                    </Fragment>
                  ): (
                    <Fragment>
                      {console.log(formState.vendor !== '' ? '' : '')}
                      <Select
                        fullWidth
                        native
                        defaultValue={''}
                        onChange={handleChange}
                        label="Vendor"
                        inputProps={{
                          name: 'vendor',
                          id: 'outlined-age-native-simple'
                        }}
                        name="vendor"
                        inputRef={register}
                        // onChange={handleChange}
                      >
                        <option aria-label="None" value="" />
                        {!isEmpty(vendors) && (
                          vendors.data.map((vendor) => (
                            <option value={vendor.id}>{vendor.name}</option>
                          ))
                        )}

                      </Select>
                    </Fragment>
                  )}

                  <FormHelperText>{errors.vendor && errors.vendor.message}</FormHelperText>
                </FormControl>

                <FormControl
                  error={errorsForm.product_id ? true : false}
                  variant="outlined" fullWidth className={classes.marginForm}>
                  <InputLabel htmlFor="outlined-age-native-simple">Product</InputLabel>
                  <Fragment>
                    <Select
                      fullWidth
                      native
                      defaultValue={formState.product_id}
                      onChange={handleChange}
                      label="Product"
                      inputProps={{
                        name: 'product_id',
                        id: 'outlined-age-native-simple'
                      }}
                      name="product_id"
                      inputRef={register}
                      // onChange={handleChange}
                    >
                      <option aria-label="None" value="" />
                      {!isEmpty(allProduct) && (
                        allProduct.data.map((product) => (
                          <option value={product.id}>{product.name} {product.weight} {product.unit}</option>
                        ))
                      )}
                    </Select>
                    <FormHelperText>{errorsForm.product_id && errorsForm.product_id[0]}</FormHelperText>
                  </Fragment>
                </FormControl>


              </Grid>
              <Grid item xl={4} md={4} sm={12} xs={12}>
                <Dropzone
                  multiple={false}
                  fileType={
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,' +
                    'application/vnd.ms-excel'
                  }
                  value={doc}
                  handleChangeBanner={handleChangeBanner}
                  // disabled={purchaseOrder.status_name !== "Pending" ? true : false}
                />
                {doc === '' ? (
                  ''
                ):(
                  // console.log(doc)
                  <Fragment>
                    <div style={{display:'flex'}}>
                      <div>
                        <Card className={classes.imageList}>
                          <CardActionArea>
                            <CardMedia
                              className={classes.media}
                              image={require('../../../../assets/images/logo/ico-xlsx.png').default}
                              title="Contemplative Reptile"
                            />
                          </CardActionArea>
                          <CardContent>
                            <Typography variant="h5">
                              {doc.name}
                            </Typography>
                          </CardContent>
                        </Card>
                      </div>
                    </div>
                  </Fragment>
                )}
              </Grid>
            </Grid>
            <div className={classes.row}>
              <hr/>
            </div>
            <div className={classes.row}>
              {doc === '' ? (
                <Button type={"submit"} variant={"contained"} size={"medium"} disabled>
                  Submit
                </Button>
              ) : (
                <Button type={"submit"} className={classes.btnSuccess} size={"medium"}>
                  Submit
                </Button>
              )}

            </div>
          </Paper>
        </form>
      </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    loading: state.scan.loading,
    loadingVendor: state.vendor.loading,
    errorsForm: state.scan.errors,
    statusDialog: state.scan.statusDialog,
    contentDialog: state.scan.contentDialog,
    changing: state.scan.changing,
    vendors: state.vendor.vendors,
    allProduct: state.product.allProduct,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onStoreScan: (storeData, vendorId, history) => dispatch(actions.storeScan(storeData,vendorId,history)),
    onClosedDialog: () => dispatch(actions.closedDialog()),
    onClearError: () => dispatch(actions.clearError()),
    onFetchVendors:() => dispatch(actions.fetchVendors()),
    onGetAllProduct:() => dispatch(actions.getAllProduct()),
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(Form);
