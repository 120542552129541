import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../../shared/utility'

const initialState = {
  vendors: {},
  changing: 0,
  errors: {},
  loading: false,
  // authRedirectPath: '/dashboard'
}

const fetchVendorsStart = (state, action) => {
  return updateObject(state, { loading: true })
}

const fetchVendorsSuccess = (state, action) => {
  return updateObject(state, {
    vendors: action.vendors,
    loading: false
  })
}

const fetchVendorsFail = (state, action) => {
  return updateObject(state, { errors: action.errors, loading: false })
}

const storeVendorStart = (state, action) => {
  return updateObject(state, { loading: true })
}

const storeVendorSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    changing: state.changing+1
  })
}

const storeVendorFail = (state, action) => {
  return updateObject(state, { errors: action.errors, loading: false })
}

const updateVendorStart = (state, action) => {
  return updateObject(state, { loading: true })
}

const updateVendorSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    changing: state.changing+1
  })
}

const updateVendorFail = (state, action) => {
  return updateObject(state, { errors: action.errors, loading: false })
}

const deleteVendorStart = (state, action) => {
  return updateObject(state, { loading: true })
}

const deleteVendorSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    changing: state.changing+1
  })
}

const deleteVendorFail = (state, action) => {
  return updateObject(state, { errors: action.errors, loading: false })
}

const clearErrorVendor = (state, action) => {
  return updateObject(state, { errors: {} })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_VENDORS_START: return fetchVendorsStart(state, action)
    case actionTypes.FETCH_VENDORS_SUCCESS: return fetchVendorsSuccess(state, action)
    case actionTypes.FETCH_VENDORS_FAIL: return fetchVendorsFail(state, action)
    case actionTypes.STORE_VENDOR_START: return storeVendorStart(state, action)
    case actionTypes.STORE_VENDOR_SUCCESS: return storeVendorSuccess(state, action)
    case actionTypes.STORE_VENDOR_FAIL: return storeVendorFail(state, action)
    case actionTypes.UPDATE_VENDOR_START: return updateVendorStart(state, action)
    case actionTypes.UPDATE_VENDOR_SUCCESS: return updateVendorSuccess(state, action)
    case actionTypes.UPDATE_VENDOR_FAIL: return updateVendorFail(state, action)
    case actionTypes.DELETE_VENDOR_START: return deleteVendorStart(state, action)
    case actionTypes.DELETE_VENDOR_SUCCESS: return deleteVendorSuccess(state, action)
    case actionTypes.DELETE_VENDOR_FAIL: return deleteVendorFail(state, action)
    case actionTypes.CLEAR_ERROR_VENDOR: return clearErrorVendor(state, action)

    default: return state
  }
}

export default reducer