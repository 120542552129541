import React, {Fragment, useState} from "react";
import * as actions from '../../../../store/actions';
import {connect} from "react-redux";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers";
import TextField from "@material-ui/core/TextField";
import {Button} from "@material-ui/core";
import {Loading} from "../../../../components/UI";

const schema = yup.object().shape({
});

const FormChangeName = props => {
  const {
    user,
    classes,
    onUpdateProfileName,
    loading,
    errors
  } = props;
  const [formState, setFormState] = useState({
    name: user.name
  });

  const handleChange = (event) => {
    const target = event.target.name
    event.persist()

    if(event.target.type === 'checkbox'){
      setFormState((formState) => ({
        ...formState,
        [target]: event.target.checked
      }))
    }else{
      setFormState((formState) => ({
        ...formState,
        [target]: event.target.value
      }))
    }
  }

  const { register, handleSubmit } = useForm({
    resolver: yupResolver(schema)
  })

  const onSubmit = data => {
    // console.log(data);
    onUpdateProfileName(data)
    // onStoreUser(data,history);
  }
  return (loading ? <Loading/> :
    <Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          // className={classes.marginForm}
          label="Nama"
          variant={"outlined"}
          name="name"
          defaultValue={formState.name}
          onChange={handleChange}
          size={"small"}
          // fullWidth
          inputRef={register}
          placeholder={"Masukan Nama User"}
          error={errors.name ? true : false}
          helperText={errors.name && errors.name[0]}
        />
        <div style={{margin: '20px auto'}}>
          <hr/>
        </div>
        <Button type={"submit"} variant={"contained"} className={classes.btnWarning} size={"small"}>
          Simpan
        </Button>
      </form>
    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    loading: state.profile.loading,
    errors: state.profile.errors,
    changing: state.profile.changing,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onUpdateProfileName: (storeData) => dispatch(actions.updateProfileName(storeData))
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(FormChangeName);