import {makeStyles} from "@material-ui/core";

const Main = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  row: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  tagMenu: {
    fontWeight: "bold"
  },
  btnWarning: {
    color: theme.palette.white,
    backgroundColor: theme.palette.warning.main,
    textTransform: "none",
    fontWeight: "bold",
    borderRadius: 5,
    '&:hover': {
      backgroundColor: theme.palette.warning.light
    }
  },
  btnSuccess: {
    color: theme.palette.white,
    backgroundColor: theme.palette.success.main,
    textTransform: "none",
    fontWeight: "bold",
    borderRadius: 5,
    '&:hover': {
      backgroundColor: theme.palette.success.light
    }
  },
  btnError: {
    color: theme.palette.white,
    backgroundColor: theme.palette.error.main,
    textTransform: "none",
    fontWeight: "bold",
    borderRadius: 5,
    '&:hover': {
      backgroundColor: theme.palette.error.light
    }
  },
  textPrimary: {
    color: theme.palette.primary.dark
  },
  textWarning: {
    color: theme.palette.warning.dark
  },
  textError: {
    color: theme.palette.error.dark
  },
  textSuccess: {
    color: theme.palette.success.dark
  },
  marginForm: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  panelPadding: {
    padding: theme.spacing(2)
  },
  warningBackrond: {
    backgroundColor: theme.palette.warning.main,
    width: '100%',
    borderRadius: '10%',
    color: theme.palette.white,
    textAlign: "center"
  },
  successBackground: {
    backgroundColor: theme.palette.success.main,
    width: '100%',
    borderRadius: '10%',
    color: theme.palette.white,
    textAlign: "center"
  },
  errorBackground: {
    backgroundColor: theme.palette.error.main,
    width: '100%',
    borderRadius: '10%',
    color: theme.palette.white,
    textAlign: "center"
  },
  flexContainer: {
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
    wordBreak: "break-word",
    height: '100%',
    justifyContent: 'space-around'
  },
  textScan: {
    width: '100%',
    height:'100%',
    textAlign: "justify"
  },
  numberScan: {
    fontWeight: 600,
    fontSize: '28px',
  },
  statusScan: {
    color: '#929292',
    fontWeight: 400,
    fontSize: '14px',
  },
  searchRoot: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 'auto',
    // marginTop: theme.spacing(2)
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  textCapitalize: {
    textTransform: "capitalize"
  }
}));

export default Main;