import { combineReducers } from 'redux'
import alert from './alert'
import auth from './auth'
import user from './user'
import profile from './profile'
import scan from './scan'
import vendor from './vendor'
import product from './product'

export default combineReducers({
    alert,
    auth,
    user,
    profile,
    scan,
    vendor,
    product
})