import React, { useEffect, useState, Fragment } from 'react'
import { useDropzone } from 'react-dropzone'

import {Button, makeStyles} from '@material-ui/core'
import {CloudUploadOutlined} from "@material-ui/icons";

const styles = makeStyles((theme) => ({
    container: {
        flex: '1',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        // padding: '20px',
        borderWidth: '2px',
        borderRadius: '8px',
        borderColor: '#eeeeee',
        borderStyle: "solid",
        backgroundColor: theme.palette.warning.main,
        color: '#FFFFFF',
        outline: 'none',
        transition: 'border .24s ease-in-out',
        height: 250,
        marginBottom: 15
    },
    btnDropzone: {
        color: theme.palette.warning.main,
        backgroundColor: theme.palette.white,
        textTransform: "none",
        fontWeight: "bold",
        borderRadius: 5,
        margin: 'auto 5%',
        width: '90%'
    },
    textIcon: {
        fontSize: '45px',
    },
    textHeader: {
        fontSize: '18px',
        fontWeight: '600'
    },
    textChild: {
        fontSize: '16px',
        textAlign: "center"
    }
}))

// const thumbsContainer = {
//   display: 'flex',
//   flexDirection: 'row',
//   flexWrap: 'wrap',
//   marginTop: 16
// }

// const thumb = {
//   display: 'inline-flex',
//   borderRadius: 2,
//   border: '1px solid #eaeaea',
//   marginBottom: 8,
//   marginRight: 8,
//   width: 100,
//   height: 100,
//   padding: 4,
//   boxSizing: 'border-box'
// }

// const thumbInner = {
//   display: 'flex',
//   minWidth: 0,
//   overflow: 'hidden'
// }

// const img = {
//   display: 'block',
//   width: 'auto',
//   height: '100%'
// }

const Dropzone = props => {
    const classes = styles()
    const { fileType, multiple, handleChangeBanner } = props

    const [files, setFiles] = useState([])
    const { getRootProps, getInputProps } = useDropzone({
        // maxSize: 1000000,
        accept: fileType,
        onDrop: acceptedFiles => {
            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })))
        },
        onDropAccepted: handleChangeBanner,
        multiple: multiple,
    })

    // const thumbs = files.map(file => (
    //   <div style={thumb} key={file.name}>
    //     <div style={thumbInner}>
    //       <img
    //         src={file.preview}
    //         style={img}
    //       />
    //     </div>
    //   </div>
    // ))

    useEffect(() => () => {
        // Make sure to revoke the data uris to avoid memory leaks
        files.forEach(file => URL.revokeObjectURL(file.preview))
    }, [files])
    // console.log(files);
    return (

            <div {...getRootProps({ className: '' })}>
                <section className={classes.container}>
                    <Fragment>
                        <input {...getInputProps()} />
                        <div>
                            <CloudUploadOutlined className={classes.textIcon}/>
                        </div><br/>
                        <div className={classes.textHeader}>
                            Import File
                        </div>
                        <div className={classes.textChild}>
                            Import file Excel atau CSV disini
                        </div><br/>
                        <div style={{width: '95%'}}>
                            <Button size={"medium"} variant={"contained"} className={classes.btnDropzone} fullWidth>
                                Import
                            </Button>
                        </div>
                    </Fragment>
                </section>
            </div>

    )
}

export default Dropzone
