import React,{Fragment} from "react";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {isEmpty} from "../../../../shared/utility";
import TableBody from "@material-ui/core/TableBody";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import {TablePaginationActions} from "../../../../components/UI";
import DataScan from "./DataScan/DataScan";

const columns = [
  // { id: 'no', label: 'No', minWidth: 100 },
  { id: 'nama_user', label: 'Gold Name', minWidth: 100 },
  { id: 'email', label: 'Gold Code', minWidth: 100 },
  { id: 'akses', label: 'Vendor Name', minWidth: 100 },
  { id: 'role', label: 'Status', minWidth: 100 },
  { id: 'role', label: 'Last ID Scan', minWidth: 100 },
  { id: 'action', label: 'Last Datetime Scan', minWidth: 100 },
  { id: 'action', label: 'Action', minWidth: 100 },
];

const List = props => {
  const {
    classes,
    scans,
    propsHandleChangePage,
    update
  } = props;

  const handleChangePage = (event, newPage) => {
    propsHandleChangePage(newPage)
  }

  const updateData = data => {
    update(data)
  }

  let tableBody = '';
  if(!isEmpty(scans)){
    const page = scans.current_page - 1;
    const rowsPerPage = 10;
    const countRows = scans.total_item
    // console.log(page, rowsPerPage, countRows, emptyRows);
    //
    tableBody = (
      <Fragment>
        <TableBody>
          {scans.data.map((scan, index) => (
            <DataScan
              scan={scan}
              key={scan.id}
              // index={index}
              classes={classes}
              edited={(data) => updateData(data)}
            />
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[rowsPerPage]}
              colSpan={10}
              count={countRows}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Fragment>
    )
  }

  return (
    <Fragment>
      {/*<Paper className={classes.root}>*/}
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table" style={{ minWidth: "340px" }}>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {tableBody}
          </Table>
        </TableContainer>
      {/*</Paper>*/}
    </Fragment>
  );
};

export default List;